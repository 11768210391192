import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldTextFC from '../common/FieldTextFC';
import FieldDate from "./FieldDate";
import Table from "../common/Table";
import Spinner from "./Spinner";
import React, { useEffect, useState } from 'react';
import generateRequestHeaders from '../../util/generateRequestHeaders';
import { useValidationList } from '../common/hooks/useValidationList';
import { useInput } from '../common/hooks/useInput';
import * as moment from "moment";
import FieldSelect from "./FieldSelect";


const SelfSignUpList = (props) => {
    let selfSignUpConditionList = [{
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        operator: 'EQUALS',
        fieldName: 'customer.onBoarding',
        fieldValue: 'SELF_SIGN_UP'
    }
    ];

    if(props?.companyId){

        selfSignUpConditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: '(',
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'company.id',
                fieldValue: props?.companyId
            }
        );

        selfSignUpConditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'OR',
                openBrackets: null,
                closeBrackets: ')',
                operator: 'EQUALS',
                fieldName: 'company.parentId',
                fieldValue: props?.companyId
            }
        );

    }


    //const [company, setCompany] = useState({});
    //const [selfSignUp, setSelfSignUp] = useState({});
    const {
        value: selfSignUpSearchFilter,
        setValue: setSelfSignUpSearchFilter,
        handleChange: handleChangeSelfSignUpSearchFilter,
      } = useInput('');
      
    const { validationList, addErrorToValidationList, setAlertMessage, clearValidationList } = useValidationList([]);
    const [selfSignUpList, setSelfSignUpList] = useState({
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [
            {}
        ]
    });
    const [selfSignUpQuery, setSelfSignUpQuery] = useState({
        orderBy: 'DESC',
        orderByFields: ['createDate'],
        conditionList: selfSignUpConditionList,
        joins: {
            customer: {
                targetRecordType: 'TYPE_CUSTOMER',
                joinField: 'userId',
                alias: 'customer',
                returnFields: ['firstName', 'lastName', 'email', 'phone', 'phoneStatus', 'customFields', 'onBoarding']
            },
            property: {
                targetRecordType: 'TYPE_PROPERTY',
                joinField: 'propertyId',
                alias: 'property',
                returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
            },
            company: {
                targetRecordType: 'TYPE_COMPANY',
                joinField: 'companyId',
                alias: 'company',
                returnFields: ['id', 'parentId']
            }
        },
    });
    
    const [spinner, setSpinner] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedTenants, setSelectedTenants] = useState([]);
    const [selectedTenantStatus, setSelectedTenantStatus] = useState('');
    const [selfSignUpStatusFilter, setSelfSignUpStatusFilter] = useState('');
    
     /**
     * Retrieve list of self sign up on mounting of the component
     */
    useEffect(() => {
        searchSelfSignUps(1, 25, selfSignUpQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.propertyId]);
   
    /**
     * Update the data table of self sign up.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    const searchSelfSignUps = (page, recordsPerPage, query) => {
        

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: generateRequestHeaders()
        }).then(response => {
            setSpinner(false);
            
            setSelfSignUpList((prevState) => ({
                ...prevState,
                ...response.data,
                emptyUnfilteredList: selfSignUpSearchFilter === '' && response.data.records.length === 0
            }));

            setSelfSignUpQuery((prevState) => ({
                 ...prevState,
                 orderBy: query.orderBy,
                 orderByFields: query.orderByFields,
                 joins: query.joins,
                 conditionList: query.conditionList
            }));

        }).catch(error => {
            addErrorToValidationList(error);
        });
    }



    /**
     * Handle submitting the self sign up search filter field by adjusting the search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    const filterSelfSignUps = (event) => {

        if(event != null) {
            event.preventDefault();
        }

        setSpinner(true);


        selfSignUpQuery.conditionList = [];
        selfSignUpQuery.joins = {
            customer: {
                targetRecordType: 'TYPE_CUSTOMER',
                joinField: 'userId',
                alias: 'customer',
                returnFields: ['firstName', 'lastName', 'email', 'phone', 'phoneStatus', 'customFields', 'onBoarding']
            },
            property: {
                targetRecordType: 'TYPE_PROPERTY',
                joinField: 'propertyId',
                alias: 'property',
                returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
            },
            company: {
                targetRecordType: 'TYPE_COMPANY',
                joinField: 'companyId',
                alias: 'company',
                returnFields: ['id', 'parentId']
            }
        };
        
        /*setStartDate(props.parent.state.startDate);
        setEndDate(props.parent.state.endDate);*/
        selfSignUpQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'customer.onBoarding',
                fieldValue: 'SELF_SIGN_UP'
            }
        );

        if(props?.companyId){

            selfSignUpQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'company.id',
                    fieldValue: props?.companyId
                }
            );

            selfSignUpQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    operator: 'EQUALS',
                    fieldName: 'company.parentId',
                    fieldValue: props?.companyId
                }
            );

        }

        if(selfSignUpSearchFilter === '') {

            if(props.propertyId) {
                selfSignUpQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: props.propertyId
                    }
                );
            }

        }

        if(selfSignUpSearchFilter !== '') {
            selfSignUpQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: selfSignUpSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'customer.firstName,customer.lastName,customer.email,unit,property.street1,property.propertyName',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: selfSignUpSearchFilter
                }
            );
        }
        
        if(startDate !== ''){
            const startDateTemp = moment.utc(startDate).startOf("day").format('YYYY-MM-DD[T]HH:mm:ssZZ');
            selfSignUpQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: startDateTemp,
                },
            );
        }
        if(endDate !== ''){
            const endDateTemp = moment.utc(endDate).endOf("day").add(1, 'days').format('YYYY-MM-DD[T]HH:mm:ssZZ');
            selfSignUpQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: endDateTemp,
                },
            );
        }

        if(selfSignUpStatusFilter !== '') {
            selfSignUpQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: selfSignUpStatusFilter
                }
            );
        }
        searchSelfSignUps(1, 25, selfSignUpQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    const clearFilters = () => {
        setSelfSignUpSearchFilter('');
        setStartDate('');
        setEndDate('');
        setSelfSignUpStatusFilter('');
    }
    useEffect(() => {
        filterSelfSignUps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfSignUpSearchFilter, selfSignUpStatusFilter, startDate, endDate]);

    /**
     * Handle change events on date fields. Convert the date to the format accepted by the server.
     *
     * @param date - The selected date value.
     */
    const handleChangeStartDate = (date) =>{
        
        let dateField;

        let dateTime = date ? moment(date).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }

        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
        
        setStartDate(dateField);
    }

    /**
     * Handle change events on from date field. Convert the date to the format accepted by the server.
     *
     * @param from - The selected date value.
     */
    const handleFromChange = (from) =>{
        
        
        let dateField;

        let dateTime = from ? moment(from).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }
        
        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;

        setStartDate(dateField); 
    }

     /**
     * Handle change events on to date field. Convert the date to the format accepted by the server.
     *
     * @param to - The selected date value.
     */
    const handleToChange = (to) =>{
        
        let dateField;

        let dateTime = to ? moment(to).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }
        
        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;

        setEndDate(dateField);
    }

    /**
     * Handle add or remove list of tenants to be Rejected.
     * 
     * @param tenantId - Tenant id
     */
    const selectTenant = (tenant) => {
        const index = selectedTenants.indexOf(tenant.id)
        if (index > -1) {
            const newArr = [...selectedTenants];
            newArr.splice(index, 1);
            setSelectedTenants(newArr);
            if (newArr.length === 0) {
                setSelectedTenantStatus('');
            }
        } else {
            setSelectedTenants([...selectedTenants, tenant.id]);
            setSelectedTenantStatus(tenant.status);
        }
    }

    /**
     * Handle reject self sign up tenants
     * 
     */
    const rejectTenants = () => {
        setSpinner(true);
        clearValidationList();
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/batch/reject`, selectedTenants, {
            headers: generateRequestHeaders()
        }).then(() => {
            setSpinner(false);
            setAlertMessage('Changes have been saved', 'primary');
            setSelectedTenants([]);
            setSelectedTenantStatus('');
            searchSelfSignUps(1, 25, selfSignUpQuery);
        }).catch(error => {
            setSpinner(false);
            setSelectedTenantStatus('');
            addErrorToValidationList(error);
        });
    }

    /**
     * Handle approve self sign up tenants
     * 
     */
    const approveTenants = () => {
        setSpinner(true);
        clearValidationList();
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/batch/approve`, selectedTenants, {
            headers: generateRequestHeaders()
        }).then(() => {
            setSpinner(false);
            setAlertMessage('Changes have been saved', 'primary');
            setSelectedTenants([]);
            setSelectedTenantStatus('');
            searchSelfSignUps(1, 25, selfSignUpQuery);
        }).catch(error => {
            setSpinner(false);
            setSelectedTenantStatus('');
            addErrorToValidationList(error);
        });
    }

    /**
     * Handle terminate self sign up tenants
     * 
     */
    const terminateTenants = () => {
        setSpinner(true);
        clearValidationList();
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/batch/terminate`, selectedTenants, {
            headers: generateRequestHeaders()
        }).then(() => {
            setSpinner(false);
            setAlertMessage('Changes have been saved', 'primary');
            setSelectedTenants([]);
            setSelectedTenantStatus('');
            searchSelfSignUps(1, 25, selfSignUpQuery);
        }).catch(error => {
            setSpinner(false);
            setSelectedTenantStatus('');
            addErrorToValidationList(error);
        });
    }

    /**
     * Handle change events on from date field. Convert the date to the format accepted by the server.
     *
     * @param from - The selected date value.
     */
    const handleChangeSelfSignUpStatusFilter = (event) =>{

        setSelfSignUpStatusFilter(event.target?.value);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The table list of self sign up.
     */
    

    let selfSignUpColumnLabels = {accountNumber: 'Account ID', createDate: 'Sign-Up Date', startDate: 'Lease Start Date', 'customer.firstName': 'Tenants', 'property.propertyName': 'Property Name', unit: 'Unit', 'property.street1': 'Property Address', monthlyPaymentDueDay: 'Payment Due', status: 'Sign-Up Status'};
    if((props.userType === 'TYPE_MANAGER' && (props?.managerRoles?.includes('PRIMARY_MANAGER') || props?.managerRoles?.includes('SUPPORT_MANAGER') || props?.managerRoles?.includes('PROPERTY_MANAGER'))) || props.userType === 'TYPE_ADMIN' || props.userType === 'TYPE_LANDLORD') {
        selfSignUpColumnLabels.selected = ''
    }
    let selfSignUpColumnWidths = ['9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '10%', '9%'];


    $(function() {
        $('[data-toggle="tooltip"]').tooltip()
    });

    return (
      <React.Fragment>
        <Spinner visible={spinner} />

        <Alert validationList={validationList} />

        {props.history.location.state && (
          <Alert
            validationList={props.history.location.state.validationList}
            validationType='primary'
          />
        )}

        <div className='card-header gotham border-top py-3 bg-secondary'>
          <form onSubmit={filterSelfSignUps}>
            <div className='media'>
              <div className='media-body align-self-center mr-3'>
                <FieldTextFC
                  id='selfSignUpSearchFilter'
                  label='Search'
                  labelClass='d-none'
                  fieldColumns='12'
                  labelColums='0'
                  placeholder='Filter by name, unit, or property...'
                  value={selfSignUpSearchFilter}
                  handleChange={handleChangeSelfSignUpSearchFilter}
                />
              </div>
              <div className="media-body align-self-center mr-3">
                  <FieldSelect id="selfSignUpStatusFilter" labelColumns="0"
                               fieldColumns="12" parent={props.parent}
                               value={selfSignUpStatusFilter}
                               handleChange={handleChangeSelfSignUpStatusFilter}>
                      <option value="">Any Self Sign-Up Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="PENDING">Pending</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="TERMINATED">Terminated</option>
                  </FieldSelect>
              </div>
              <div className='text-black-50 align-self-center mr-3'>Sign-up Date:</div>
              <div className='media-body align-self-center mr-3'>
                <FieldDate
                  id='startDate'
                  labelColumns='0'
                  fieldColumns='12'
                  value={startDate}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  handleChange={handleChangeStartDate}
                  handleFromChange={handleFromChange}
                  handleToChange={handleToChange}
                  startDatePlaceHolder='From YYYY-MM-DD'
                  endDatePlaceHolder='To YYYY-MM-DD'
                />
              </div>
              <div className='align-self-center text-right'>
                <div className='btn-group' role='group' aria-label='Basic example'>
                  <button type='submit' className='btn btn-secondary btn-sm mb-0'>
                    <FontAwesomeIcon icon={['fas', 'search']} className='fa-fw' /> Search
                  </button>
                  <div className='btn btn-secondary btn-sm mb-0' onClick={() => clearFilters()}>
                    <FontAwesomeIcon icon={['fas', 'eraser']} className='fa-fw' /> Clear
                  </div>
                </div>
              </div>
            </div>
          </form>
          {selectedTenants.length > 0 && (
            <div className='d-flex justify-content-end'>
              {selectedTenantStatus === 'ACTIVE' ? (
                <button
                  className='btn btn-outline-danger btn-sm mb-0 mt-2 ml-2'
                  onClick={terminateTenants}
                >
                  <FontAwesomeIcon icon={['fas', 'trash']} className='fa-fw' /> Terminate Tenant
                  {selectedTenants.length > 1 ? 's' : ''}
                </button>
              ) : (
                <button
                  className='btn btn-outline-primary btn-sm mb-0 mt-2'
                  onClick={approveTenants}
                >
                  <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Approve Tenant
                  {selectedTenants.length > 1 ? 's' : ''}
                </button>
              )}
              {selectedTenantStatus === 'PENDING' && (
                  <button
                    className='btn btn-outline-danger btn-sm mb-0 mt-2 ml-2'
                    onClick={rejectTenants}
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} className='fa-fw' /> Reject Tenant
                    {selectedTenants.length > 1 ? 's' : ''}
                  </button>
                )}
            </div>
          )}
        </div>

        {!selfSignUpList.emptyUnfilteredList && (
          <Table
            columns={selfSignUpColumnLabels}
            columnWidths={selfSignUpColumnWidths}
            headerClass='c-pointer'
            data={selfSignUpList}
            query={selfSignUpQuery}
            sortEnabled={true}
            recordsEnabled={true}
            paginationEnabled={true}
            parent={props.parent}
            removeSortForColumn={['selected']}
            updateFunction={searchSelfSignUps}
            tableClass='table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed'
          >
            <tbody>
              {selfSignUpList.records.map((data, key) => {
                const { formatMessage } = props.intl;
                return (
                  <tr key={key}>
                    <td>
                      <div className=''>{data.accountNumber}</div>
                    </td>
                    <td>
                      <div className=''>
                        <Moment format='MMM DD, YYYY' tz='UTC'>
                          {data.createDate}
                        </Moment>
                      </div>
                    </td>
                    <td>
                      <div className=''>
                        <Moment format='MMM DD, YYYY' tz='UTC'>
                          {data.startDate}
                        </Moment>
                      </div>
                    </td>
                    <td>
                      <div className=''>
                        {data.joins?.customer?.firstName} {data.joins?.customer?.lastName}
                      </div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.property?.propertyName}</div>
                    </td>
                    <td>
                      <div className=''>{data.unit}</div>
                    </td>
                    {props.context !== 'property' && (
                      <td>
                        <div className=''>{data.joins?.property?.street1}</div>
                      </td>
                    )}
                    <td>
                      <div className='text-nowrap'>
                        <span className='ml-1'>{data.monthlyPaymentDueDay}</span>
                      </div>
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        className={`fa-fw small ${formatMessage({
                          id: 'enum.selfSignUp.status.' + data.status + '.class',
                        })}`}
                      />
                      <span className='ml-1 text-break text-wrap'>
                        <FormattedMessage id={'enum.selfSignUp.status.' + data.status} />
                      </span>
                    </td>
                    {((props.userType === 'TYPE_MANAGER' &&
                      (props?.managerRoles?.includes('PRIMARY_MANAGER') ||
                        props?.managerRoles?.includes('SUPPORT_MANAGER') ||
                          props?.managerRoles?.includes('PROPERTY_MANAGER'))) ||
                      props.userType === 'TYPE_ADMIN' ||
                      props.userType === 'TYPE_LANDLORD') && (
                      <td data-checkbox-cell='true'>
                        <div className={`custom-control custom-checkbox`} data-checkbox-cell='true'>
                          <input
                            type='checkbox'
                            id={`self-signup-${data?.id}`}
                            name={data?.id}
                            value={data?.userId}
                            className={`custom-control-input position-relative w-50`}
                            data-checkbox-cell='true'
                            checked={selectedTenants.includes(data?.id)}
                            onChange={() => selectTenant(data)}
                            disabled={
                              (selectedTenantStatus !== data.status &&
                                selectedTenantStatus !== '') ||
                              data.status === 'TERMINATED'
                            }
                          />
                          <label
                            htmlFor={`self-signup-${data?.id}`}
                            className={`custom-control-label pt-1 text-break`}
                            data-checkbox-cell='true'
                          ></label>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </React.Fragment>
    );

}

SelfSignUpList.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SelfSignUpList);