import axios from 'axios';
import React from 'react';
import Propertii from '../../common/Propertii';
import {injectIntl} from "react-intl";
import * as constants from "../../../util/constants";
import Spinner from "../../common/Spinner";

class bi extends Propertii {
    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);
        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/bi/dashboard`, {
            headers: this.generateRequestHeaders(),
        })
            .then((response) => {
                this.setState(prevState => ({
                    ...prevState,
                    biHtml: response.data,
                }));
            })
            .catch((error) => {
                this.handleValidation(error);
            });
    }

    handleIframeLoad() {
        document.getElementById('spinner').style.display='none';
    };

    /**
     * Render the component.
     *
     * @returns {*} - The administrator dashboard component.
     */

    render() {
        return (

            <div style={{background: '#E8E8E8'}}>
                <Spinner id={"spinner"} visible={true} />
                <div style={{marginLeft: 100, marginRight: 100, marginTop: 0, marginBottom: 5}}>
                    <iframe height="10000" style={{width: "100%", border: "0px"}} srcDoc={this.state.biHtml} onLoad={this.handleIframeLoad}/>
                </div>
            </div>

    );
    }
}


export default injectIntl(bi);
