import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Table from "../common/Table";
import FieldSelect from "./FieldSelect";
import Spinner from "./Spinner";


class LeaseApplications extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let leaseApplicationConditionList = [];

        if(this.props.propertyId) {
            leaseApplicationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        leaseApplicationConditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'status',
                fieldValue: 'PENDING_FOR_APPROVAL'
            }
        );

        this.state = {

            company: {},
            leaseApplication: {},

            leaseApplicationSearchFilter: '',
            leaseApplicationStatusFilter: 'PENDING_FOR_APPROVAL',

            leaseApplicationLinkCopied: false,
            leaseApplicationEmail: {},
            leaseApplicationPageList: [{}],
            selectedApplicationPage: {pageName: null},
            leaseApplicationEmailFields: {
                firstName: '',
                lastName: '',
                email: '',
                companyId: this.props.companyId,
                pageName: '',
            },

            leaseApplicationList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            leaseApplicationQuery: {
                orderBy: 'DESC',
                orderByFields: ['updateDate'],
                conditionList: leaseApplicationConditionList,
                joins: {
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', "street1"]
                    },
                },
            },

            validationList: [],

        };

        this.searchLeaseApplications = this.searchLeaseApplications.bind(this);
        this.filterLeaseApplications = this.filterLeaseApplications.bind(this);

        this.clearFilters = this.clearFilters.bind(this);

        this.selectLeaseApplication = this.selectLeaseApplication.bind(this);
        this.getLeaseApplicationPages = this.getLeaseApplicationPages.bind(this);
        this.initLeaseApplicationEmail = this.initLeaseApplicationEmail.bind(this);
        this.sendLeaseApplicationEmail = this.sendLeaseApplicationEmail.bind(this);
        
        this.getAllCompanies = this.getAllCompanies.bind(this);
        this.getLeaseApplicationPagesProcess = this.getLeaseApplicationPagesProcess.bind(this);
        this.cleanSendEmailForm = this.cleanSendEmailForm.bind(this);
    }

    /**
     * Retrieve list of lease applications on mounting of the component, as well as the company at hand.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}/peek`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                company: response.data,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });


        this.searchLeaseApplications(1, 25, this.state.leaseApplicationQuery);
    }

    /**
     * Fetch all the companies under a manager
     * @param id - parentCompanyId 
     */
    getAllCompanies(parentCompanyId) {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search`, {
            orderBy: 'ASC',
            orderByFields: ['updateDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'parentId',
                    operator: 'EQUALS',
                    fieldValue: parentCompanyId,
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        });
    }


    /**
     * Calls the API to fetch all the available lease applications
     * @param {*} leaseApplicationPageQuery 
     */
    getLeaseApplicationPagesProcess(leaseApplicationPageQuery) {
        
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/search`, {
            orderBy: leaseApplicationPageQuery.orderBy,
            orderByFields: leaseApplicationPageQuery.orderByFields,
            conditionList: leaseApplicationPageQuery.conditionList,
            joins: leaseApplicationPageQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPageList: response.data.records,
                selectedApplicationPage: {pageName: response.data.records.length > 0 ? response.data.records[0].pageName : null },
                leaseApplicationEmailFields: {pageName: response.data.records.length > 0 ? response.data.records[0].pageName : null }
            }));

            if(response.data.records.length){
                $('#new-applicant').modal('show');
            }else{
                $('#advise-support').modal('show');
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Get all the lease applications under all the available companies
     */
    getLeaseApplicationPages() {

        let leaseApplicationPageQuery = {
            orderBy: 'DESC',
            orderByFields: ['pageName'],
            conditionList: [],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name']
                }
            }
        };

        this.getAllCompanies(this.props.companyId).then(response => {
            const companies = response.data.records; 

            companies.forEach(company => {
                leaseApplicationPageQuery.conditionList.push({
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: company.id,
                });
            });

            this.getLeaseApplicationPagesProcess(leaseApplicationPageQuery);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of lease applications.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchLeaseApplications(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                leaseApplicationList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.leaseApplicationSearchFilter === '' && response.data.records.length === 0
                },
                leaseApplicationQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select an existing lease application. Redirects the user to the application view component.
     *
     * @param leaseApplicationId - The ID of the lease application.
     */
    selectLeaseApplication(leaseApplicationId) {

        this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/applications/${leaseApplicationId}/view`);
    }

    /**
     * Initialize the lease application email invitation flow. Creates a new instance of an email object and performs a
     * search for all lease application pages related to the company ID provided in the props, which is used as a merge
     * field in the email that will be sent out.
     */
    initLeaseApplicationEmail() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/email/new`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationEmail:  response.data,

            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#new-applicant').modal('hide');
    }

    /**
     * Send an email to a specific email address with a link to the company's lease application submission form.
     *
     * @param event - The event container.
     */
    sendLeaseApplicationEmail(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/invite`, {
            firstName: this.state.leaseApplicationEmailFields.firstName,
            lastName: this.state.leaseApplicationEmailFields.lastName,
            email: this.state.leaseApplicationEmailFields.email,
            applicationPath: this.state.leaseApplicationEmailFields.pageName ? `apply/${this.state.leaseApplicationEmailFields.pageName}?fn=${this.state.leaseApplicationEmailFields.firstName}` : `apply/default?c=${this.props.companyId}&fn=${this.state.leaseApplicationEmailFields.firstName}&ln=${this.state.leaseApplicationEmailFields.lastName}&e=${encodeURIComponent(this.state.leaseApplicationEmailFields.email)}`,
            companyName: this.state.company.name,
            language: this.state.company.languageCode ? this.state.company.languageCode : 'en'
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.applications.sent'
                    },
                    values: {
                        email: this.state.leaseApplicationEmailFields.email,
                    }
                }],
            });

            $('#send-application').modal('hide');
            this.cleanSendEmailForm();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Clean Send email form after submit email.
     */
     cleanSendEmailForm() {
        this.setState(prevState => ({
            ...prevState,
            leaseApplicationEmailFields: {
                firstName: '',
                lastName: '',
                email: '',
                companyId: this.props.companyId,
                pageName: '',
            },
        }));                           
     }

    /**
     * Handle submitting the lease application search filter field by adjusting the search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterLeaseApplications(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let leaseApplicationQuery = this.state.leaseApplicationQuery;

        leaseApplicationQuery.conditionList = [];

        if(this.state.leaseApplicationSearchFilter === '') {

            if(this.props.propertyId) {
                leaseApplicationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

        }

        if(this.state.leaseApplicationSearchFilter !== '') {
            leaseApplicationQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.leaseApplicationSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email,unit,p.street1,p.propertyName',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.leaseApplicationSearchFilter
                }
            );
        }

        leaseApplicationQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'status',
                fieldValue: this.state.leaseApplicationStatusFilter
            }
        );

        this.setState({
            leaseApplicationQuery: leaseApplicationQuery,
        });

        this.searchLeaseApplications(1, 25, leaseApplicationQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            leaseApplicationSearchFilter: '',
            leaseApplicationStatusFilter: 'PENDING_FOR_APPROVAL',
        }, () => {

            this.filterLeaseApplications();

        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;

        let leaseApplicationColumnLabels = {updateDate: 'Date', applicationID: 'Application ID', firstName: 'Applicant', 'p.propertyName': 'Property Name', unit: 'Unit', 'p.street1': 'Property Address', status: 'Status'};
        let leaseApplicationColumnWidths = ['14%', '14%', '14%', '14%', '14%', '16%', '14%'];

        if(this.props.context === 'property') {
            leaseApplicationColumnLabels = {updateDate: 'Date', firstName: 'Applicant', unit: 'Unit', existingTenant: 'Type', status: 'Status'};
            leaseApplicationColumnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                {/* <div className="card card-primary border-info">
                    <div className="card-body">
                        <p className="card-text">
                           A new and improved version of Applications will be available soon.
                           Please contact support for more information.
                       </p>
                   </div>
                </div> */}
               
                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} validationType="primary" />
                }

                <div className={this.props.hiddenHeader ? '' : 'card'}>

                    {!this.props.hiddenHeader &&
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Applications
                            </div>
                            <div className="col text-right">
                                {this.props.newApplicant &&
                                <React.Fragment>

                                    {!this.props.newApplicantDisabled &&
                                    <div data-toggle="modal" className="btn btn-primary btn-sm" onClick={() => this.getLeaseApplicationPages()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Applicant
                                    </div>
                                    }

                                    {this.props.newApplicantDisabled &&
                                    <div className="btn btn-primary btn-sm ml-2 disabled" data-toggle={this.props.newApplicantTooltip ? 'tooltip' : ''} data-placement="top" title={this.props.newApplicantTooltip}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Applicant
                                    </div>
                                    }

                                </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                    }

                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterLeaseApplications}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="leaseApplicationSearchFilter" label="Search" labelClass="d-none"
                                               fieldColumns="12"
                                               labelColums="0" placeholder="Filter by name, unit, or property..." parent={this}
                                               value={this.state.leaseApplicationSearchFilter}/>
                                </div>
                               
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="leaseApplicationStatusFilter" labelColumns="0"
                                                 fieldColumns="12" parent={this}
                                                 value={this.state.leaseApplicationStatusFilter}>
                                        <option value="PENDING_FOR_APPROVAL">Pending for Approval</option>
                                        <option value="APPROVED">Approved Applications</option>
                                        <option value="DECLINED">Declined Applications</option>
                                        <option value="WAITING_FOR_VERIFICATION">Waiting for Verification</option>
                                       
                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                        <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                            <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {this.state.leaseApplicationList.emptyUnfilteredList &&
                    <div className="card-body bg-secondary py-5 border-top">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-muted">
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['far', 'address-card']} className="fa-stack-1x fa-inverse" />
                                    </div>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        Lease applications for prospective tenants will appear here.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {!this.state.leaseApplicationList.emptyUnfilteredList &&
                    <Table columns={leaseApplicationColumnLabels}
                           columnWidths={leaseApplicationColumnWidths}
                           headerClass="c-pointer"
                           data={this.state.leaseApplicationList}
                           query={this.state.leaseApplicationQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           parent={this}
                           updateFunction={this.searchLeaseApplications}>
                        <tbody>
                        {this.state.leaseApplicationList.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={() => this.selectLeaseApplication(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            <Moment format="MMM DD, YYYY">
                                                {data.updateDate}
                                            </Moment>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.customFields?.customApplicationId}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.firstName} {data.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        {data.joins && data.joins.p &&
                                        <div className="">
                                             {data?.joins?.p?.propertyName}
                                        </div>
                                        }
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.unit}
                                        </div>
                                    </td>
                                    {this.props.context !== 'property' &&
                                    <td>
                                        {data.joins && data.joins.p &&
                                        <div className="">
                                            {data.joins.p.street1}
                                        </div>
                                        }
                                    </td>
                                    }
                                    <td>
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.leaseApplication.status." + (data.status ? data.status : 'WAITING_VERIFICATION') + ".class"})}`}/>
                                            <span className="ml-1"><FormattedMessage id={"enum.leaseApplication.status." + (data.status ? data.status : 'WAITING_VERIFICATION')}/></span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    }

                </div>

                <div className="modal fade" id="new-applicant" tabIndex="-1" role="dialog" aria-labelledby="new-applicant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="new-applicant-label">
                                    New Applicant
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row justify-content-center">

                                    <div className="col-md-6 text-center mb-4 mb-sm-0">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Share a Link
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'link']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Copy the link below to your website or external rental listing service to accept applications.
                                            </p>
                                            {this.state.leaseApplicationPageList.length > 0 &&
                                           <div className="row">
                                               <div className="input-group mb-3">
                                                   <FieldSelect id="pageName" fieldStyle={{height :'38px'}} model="selectedApplicationPage" fieldColumns="12" parent={this} value={this.state.selectedApplicationPage.pageName}>
                                                       {this.state.leaseApplicationPageList &&
                                                       <React.Fragment>
                                                           {this.state.leaseApplicationPageList.map((data, key) => {
                                                               return (
                                                                   <option key={key} value={data.pageName}>
                                                                     [{data.joins?.c?.name}] {constants.REACT_APP_HOST_ROOT_URL}/apply/{data.pageName}
                                                                   </option>
                                                               );
                                                           })}
                                                       </React.Fragment>
                                                       }
                                                   </FieldSelect>
                                                   <div className="input-group-append" style={{"marginBottom" : "1em"}}>
                                                       <CopyToClipboard text={`${constants.REACT_APP_HOST_ROOT_URL}/apply/${this.state.selectedApplicationPage.pageName}`} onCopy={() => this.setState({leaseApplicationLinkCopied: true})}>
                                                           <button className="btn btn-primary btn-md" type="button" data-toggle="tooltip" data-placement="top" title="Copy to clipboard">
                                                               <FontAwesomeIcon icon={['far', this.state.leaseApplicationLinkCopied ? 'check' : 'copy']} className="fa-fw" />
                                                           </button>
                                                       </CopyToClipboard>
                                                   </div>
                                               </div>
                                           </div>
                                            }
                                            {this.state.leaseApplicationPageList.length === 0 &&
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" value={`${constants.REACT_APP_HOST_ROOT_URL}/apply/default?c=${this.props.companyId}`} aria-label="Application URL" aria-describedby="button-addon2" />
                                                <div className="input-group-append">
                                                    <CopyToClipboard text={`${constants.REACT_APP_HOST_ROOT_URL}/apply/default?c=${this.props.companyId}`} onCopy={() => this.setState({leaseApplicationLinkCopied: true})}>
                                                        <button className="btn btn-primary" type="button" data-toggle="tooltip" data-placement="top" title="Copy to clipboard">
                                                            <FontAwesomeIcon icon={['far', this.state.leaseApplicationLinkCopied ? 'check' : 'copy']} className="fa-fw" />
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 text-center mb-4 mb-sm-0 border-left">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Send an Email
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Click the button below to send a personalized invitation to fill out an application.
                                            </p>
                                            <div data-toggle="modal" data-target="#send-application" className="btn btn-primary btn-md" onClick={() => this.initLeaseApplicationEmail()}>
                                                Send an Email
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="modal fade" id="send-application" tabIndex="-1" role="dialog" aria-labelledby="send-application-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.sendLeaseApplicationEmail}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="send-application-label">
                                        Send an Email
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Enter the details of the individual you would like to send a personalized application link to.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="firstName" label="First Name" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['lastName']} />

                                    <FieldText id="email" type="email" label="Email" model="leaseApplicationEmailFields" required={true} parent={this} value={this.state.leaseApplicationEmailFields['email']} />

                                    {this.state.leaseApplicationPageList.length > 0 &&
                                    <FieldSelect id="pageName" label="Portal" help="Use this field to determine what page the recipient will be directed to from your email." model="leaseApplicationEmailFields" parent={this} value={this.state.leaseApplicationEmailFields['pageName']}>
                                        {/*<option value="">Default lease application portal</option>*/}
                                        {this.state.leaseApplicationPageList &&
                                        <React.Fragment>
                                            {this.state.leaseApplicationPageList.map((data, key) => {
                                                return (
                                                    <option key={key} value={data.pageName}>{constants.REACT_APP_HOST_ROOT_URL}/apply/{data.pageName}</option>
                                                );
                                            })}
                                        </React.Fragment>
                                        }
                                    </FieldSelect>
                                    }

                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Send Email</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>

                <div className="modal fade" id="advise-support" tabIndex="-1" role="dialog" aria-labelledby="advise-support-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="advise-support-label">
                                    Contact Support
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row justify-content-left">
                                    <div className="col-md-12 text-left mb-4 mb-sm-0">
                                        <div className="px-3">                                           
                                            <p className="mb-2">
                                                Please contact Letus Admin or Account Manager to create a customized Lease Application page for your business.
                                            </p>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

LeaseApplications.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LeaseApplications);