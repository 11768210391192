import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import ButtonClose from "./ButtonClose";
import FieldDate from "./FieldDate";
import FieldSelect from "./FieldSelect";
import FieldProperty from "./FieldProperty";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Table from "./Table";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

class ReportTxt2Pay extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            textMessage: {},
            textMessageFromExport: {},

            txtMessageList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            txtMessageQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {}
            },

            dateFormat: 'YYYY-MM-DD[T]hh:mm:ssZZ',
            startDate: moment().utc().subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDate: null,

            parameters: {
                propertyId: null,
                messageType: null
            },

            selectedProperty: null,
            propertyList: [],

            messageTypeFilter: null,

            bankAccountList: [],

            validationList: [],

        };


        this.searchProperties = this.searchProperties.bind(this);
        this.filterMessages = this.filterMessages.bind(this);
        this.searchTxt2PayPreview = this.searchTxt2PayPreview.bind(this);
        this.downloadTxt2Pay = this.downloadTxt2Pay.bind(this);
        this.viewTextMessage = this.viewTextMessage.bind(this);
        this.initiliazeComponent = this.initiliazeComponent.bind(this);
        this.transformDataForPDF = this.transformDataForPDF.bind(this);
        this.getHeaderForPDF = this.getHeaderForPDF.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {

        this.searchProperties();
        this.initiliazeComponent();
    }

    componentDidUpdate(prevProps){
        
        if(prevProps.companyId !== this.props.companyId){
            this.initiliazeComponent();
        }
    }

    initiliazeComponent(){
        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        this.searchTxt2PayPreview(1, 25, filter);
    }

    /**
     * View the detailed information of text message selected by user
     *
     * @param textMessage - The text message to view.
     */
    viewTextMessage(textMessage) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/text_message/${textMessage.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                textMessage: response.data,
                textMessageFromExport: textMessage
            }));
        }).catch(error => {
            this.handleValidation(error);
        });        

        $('#text-message').modal('show');
    }

    /**
     * Update the data table of text massages.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param filter - The filters to apply to the search.
     */
    searchTxt2PayPreview(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_txt2pay?recordsPerPage=${recordsPerPage}&page=${page}`, query, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                txtMessageList: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Apply the available search filters on the EFT list and perform a new search.
     *
     * @param event - The event container.
     */
    filterMessages(event) {

        if(event != null) {
            event.preventDefault();
        }

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.fromDate != null) {
            filter.startDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.endDate = moment(this.state.endDate).utc().format(this.state.dateFormat);
        }

        if(this.state.selectedProperty?.id != null) {
            filter.parameters.propertyId = this.state.parameters.propertyId;
        }

        if(this.state.messageTypeFilter != null){
            filter.parameters.messageType = this.state.messageTypeFilter;
            filter.parameters.messageTypes = null;
        } else {
            filter.parameters.messageTypes = "[\"PAYMENT\", \"ADVANCE_REMINDER\", \"OVERDUE_REMINDER\", \"WELCOME\"]";
        }

        this.searchTxt2PayPreview(1, 25, filter);
    }

    /**
     * Search properties
     */

    searchProperties(){
        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.parentId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                },
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
     downloadTxt2Pay(event) {

        event.preventDefault();

        $("#download-format-confirm").modal("show")
    }

    /**
     * Download the Txt2Pay report in CSV format. The downloaded file is based on the filters set by the user.
     *
     * @param event - The event container.
     */
    downloadTxt2PayCSV() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        }

        if(!this.state.messageTypeFilter){
            params.parameters.messageType = null;
            params.parameters.messageTypes = "[\"PAYMENT\", \"ADVANCE_REMINDER\", \"OVERDUE_REMINDER\", \"WELCOME\"]";
        } else {
            params.parameters.messageTypes = null;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_txt2pay/download`, params, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

     /**
     * Download the txt2pay report in PDF format. The downloaded file is based on the filters set by the user.
     *
     * 
     */
      downloadTxt2PayPDF() {

        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }

        if(!this.state.messageTypeFilter){
            params.parameters.messageType = null;
            params.parameters.messageTypes = "[\"PAYMENT\", \"ADVANCE_REMINDER\", \"OVERDUE_REMINDER\", \"WELCOME\"]";
        } else {
            params.parameters.messageTypes = null;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_txt2pay/download`, params, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape', 'mm', [297, 415]);

            doc.setFontSize(12);
            doc.text('Txt2Pay Report', 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak: false,
                theme: 'grid',
            
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 25 },
                    1: { cellWidth : 50 },
                    2: { cellWidth : 30 },
                    3: { cellWidth : 15 },
                    4: { cellWidth : 30 },
                    5: { cellWidth : 30 },
                    6: { cellWidth : 30 },
                    7: { cellWidth : 25 },

                    8: { cellWidth : 20 },
                    9: { cellWidth : 20 },
                    10: { cellWidth : 20 },
                    11: { cellWidth : 30 },
                    12: { cellWidth : 20 },
                    13: { cellWidth : 40 },
                }    
              });
            doc.save('Txt2Pay.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
      
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - The data returned by the endpoint to be converted into PDF.
     */
     getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
      
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }


    /**
     * Render the component.
     *
     * @returns {*} - The EFT report component.
     */
    render() {        

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Txt2Pay
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadTxt2Pay(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterMessages} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="startDate" labelColumns="0"
                                                fieldColumns="12" parent={this} 
                                                value={this.state.startDate}
                                                selectsRange={true} startDate={this.state.startDate}
                                                endDate={this.state.endDate}/>
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldProperty id="propertyId" label="Property" model="parameters" fieldColumns="12"
                                                   labelClass="d-none" labelColumns="0" 
                                                   value={this.state.selectedProperty} parent={this}
                                                   options={this.state.propertyList} allPropertiesOption={true} />
                                </div>
                                <div className="media-body text-center align-self-center mr-3">
                                    <FieldSelect id="messageTypeFilter" fieldColumns="12" labelColumns="0" parent={this}
                                                 value={this.state.messageTypeFilter}>
                                        <option value="">All Message Types</option>
                                        <option value="PAYMENT">Request for Payment</option>
                                        <option value="ADVANCE_REMINDER">Advance Payment Reminder</option>
                                        <option value="OVERDUE_REMINDER">Overdue Payment Reminder</option>
                                        <option value="WELCOME">Welcome</option>

                                        {/* <option value="MARKETING">Marketing</option> */}
                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table 
                           tableClass = "table-bordered table-hover table-responsive" 
                           columns={{
                                companyName: 'Company Name',
                                propertyName: 'Property Name',
                                propertyAddress: 'Property Address',
                                unit: 'Unit',
                                firstName: 'First Name',
                                lastName: 'Last Name',
                                mobilePhone: "Mobile Phone",
                                sendDate: "Sent Date",
                                messageType: "Message Type",
                                status: "Delivery Status",
                                txt2PayPayment: "Txt2Pay Payment",
                                paymentDate: 'Payment Date',
                                paymentAmount: 'Payment Amount',
                                numberOfMessagesSinceLastPayment: 'Number of Messages Since Last Payment'
                           }}
                           minColumnWidths={[
                               '150px',
                               '150px',
                               '150px',
                               '100px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                               '150px',
                            ]}
                           headerClass=""
                           data={this.state.txtMessageList}
                           filters={{
                               startDate: this.state.startDate,
                               endDate: this.state.endDate,
                               parameters: this.state.parameters
                           }}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchTxt2PayPreview}>
                        <tbody>
                        {this.state.txtMessageList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewTextMessage(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.companyName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.propertyName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.propertyAddress}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.unit}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.firstName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.mobilePhone}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.sendDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.sendDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.messageType}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.status}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.txt2PayPayment}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.paymentDate && data.paymentDate !== "N/A" &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.paymentDate}
                                            </Moment>
                                            }
                                            {data.paymentDate && data.paymentDate === "N/A" &&
                                                data.paymentDate
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.paymentAmount}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.numberOfMessagesSinceLastPayment}
                                        </div>
                                    </td>
                                    
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="text-message" tabIndex="-1" role="dialog" aria-labelledby="eft-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        Text Message
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0 border-bottom">
                                    <tbody>
                                    <tr className="small">
                                        <td className="">
                                            Company Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.companyName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Property Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.propertyName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Property Address
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.propertyAddress}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Unit
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.unit}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Tenant Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.firstName} {this.state.textMessageFromExport.lastName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Mobile Phone
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.mobilePhone} 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Sent Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.sendDate &&
                                                <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                    {this.state.textMessageFromExport.sendDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Message Type
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.messageType}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Delivery Status
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.status}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Txt2Pay Payment
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.txt2PayPayment}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Payment Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.paymentDate}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Payment Amount
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.paymentAmount}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Number of Text Messages Since Last Payment
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.textMessageFromExport.numberOfMessagesSinceLastPayment}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadTxt2PayCSV()}>
                            CSV Format
                        </button>
                        <button onClick={() => this.downloadTxt2PayPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            PDF Format
                        </button>
                </Modal>

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportTxt2Pay);
