import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios/index";
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import * as Scroll from 'react-scroll';
import modernTower from "../../media/img/backgrounds/modern-building.jpg";
import womanChair from "../../media/img/backgrounds/woman-chair.jpg";
import creditCards from "../../media/img/mockups/credit-cards.png";
import paymentFlow from "../../media/img/mockups/payment-flow.png";
import recurringPayment from "../../media/img/mockups/recurring-payment.png";
import transactions from "../../media/img/mockups/transactions.png";
import americanExpress from "../../media/img/payments/american-express.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import discover from "../../media/img/payments/discover.png";
import mastercard from "../../media/img/payments/mastercard.png";
import unionPay from "../../media/img/payments/unionpay.png";
import visa from "../../media/img/payments/visa.png";
import * as constants from '../../util/constants';
import Alert from "../common/Alert";
import { useValidationList } from '../common/hooks/useValidationList';
import Modal from "../common/Modal";
import Spinner from "../common/Spinner";

const Landing = (props) => {
    
    /***** SHARED STATE ***/
    /*********************/
    const [selectedTab, setSelectedTab] = useState('');
    const [reCaptchaNewAccountId, setReCaptchaNewAccountId] = useState(null);
    const [showSpinner] = useState(false);
    const { validationList, addErrorToValidationList, setAlertMessage, clearValidationList } = useValidationList([]);
    
    const [lockUsername, setLockUserName] = useState(false);

    const [confirmpassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [newAccountUsername, setNewAccountUserName] = useState('');
    const [newAccountPhone, setNewAccountPhone] = useState('');
    const [receiveTxtMessageBool, setReceiveTxtMessage] = useState(false);
    const [newAccountPassword, setNewAccountPassword] = useState('');
    const [enableCreateAccountNewAccountButton, setEnableCreateAccountNewAccountButton] =useState(true);
    const [onboardingPage, setOnboardingPage] = useState(false);

    
        
  
    /***** SHARED ACTIONS ******/
    /***************************/
    useEffect(() => {
      initApp();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    /**
    * Init the App with states for selected Tab and recaptchaID
    */
    const initApp = () => {
        $('[name="passwordIconEyeSlash"]').hide();
        $('[name="confirmPasswordIconEyeSlash"]').hide();

        setSelectedTab('NEWACCOUNT');
        setReCaptchaNewAccountId(null);
        validateFocusSignIn();
        setLockUserName(false);
        setEnableCreateAccountNewAccountButton(true);
        getOnboardingData();
        setupNoindexTag();
    }

    const getOnboardingData = () => {
        const pageName = props.match?.params?.pageName;
        axios.get(`${constants.REACT_APP_HOST_API_URL}/on_boarding_page/name/${pageName}`).then((res) => {
            setOnboardingPage(res.data)
        }).catch((err) => {
            if(err?.response?.data?.errorCode === 'RM_ERROR_RECORD_NOT_FOUND') {
                props.history.push('/notfound');
            }
        })
    }
    
     /**
     * Validate if the SIGN IN tab should be focus
     *
     */
     const validateFocusSignIn = () => {
        const queryString = window.location.search;
        const urlParameters = new URLSearchParams(queryString);
        let focus = urlParameters.get("focus");

        if(focus !== null && focus === 'signin'){
            setSelectedTab('SIGNIN');   
            selectTab('SIGNIN');
        }
    }

     /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
      const selectTab = (tabId) => {
        
        clearValidationList();

        switch(tabId) {
            case "SIGNIN":
                $('#newaccount').tab('dispose');
                $('#signin').tab('show');
                break;

            case "NEWACCOUNT":
                $('#signin').tab('dispose');
                $('#newaccount').tab('show');
                break;

            default:
                $('#newaccount').tab('dispose');
                $('#signin').tab('show');
                break;
        }
    }

    /**
     * Submit new account
     *
     * @param event - The event container.
     */
     const submitNewAccount = (event) =>{

        event.preventDefault();

        if(newAccountPassword !== confirmpassword) {
            clearValidationList();
            setAlertMessage('Password and Confirm Password mismatch.');
        }
        else {
            const queryString = window.location;
            const urlElements = queryString.pathname.split('/');
            const pageName = urlElements[2];
            
            axios.post(`${constants.REACT_APP_HOST_API_URL}/selfsignup?pageName=${pageName}`, {
                user: {
                    "type": "TYPE_CUSTOMER",
                    "email":newAccountUsername,
                    "firstName": firstName,
                    "lastName": lastName,
                    "phone": newAccountPhone,
                    "muteTextMessageNotification": !receiveTxtMessageBool,
                },
                username: newAccountUsername,
                password: newAccountPassword,
                confirmPassword: confirmpassword,
            }).then(response => {

                $("#info-verify-email").modal('show');
                clearFormNewAccount();

            }).catch(error => {
                
                clearValidationList();
                addErrorToValidationList(error);
            });
        }
    }

    /**
     * Clear Form New account when the account is created successfully
     *
     */
     const clearFormNewAccount = () =>{
        setConfirmPassword('');
        setFirstName('');
        setLastName('');
        setNewAccountUserName('');
        setNewAccountPassword('');
        setReCaptchaNewAccountId(null);
        setNewAccountPhone('');
        setReceiveTxtMessage(false);
        window.grecaptcha.reset();
     }

     /**
     * Handle Change forNew Account Email, Password, Confirm Password
     *
     *
     */
     const handleNewAccountChange = (e) => {
        

        switch(e.target.name) {
            case "firstName":
                setFirstName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
            case "newAccountUsername":
                    setNewAccountUserName(e.target.value);
                    break;
            case "newAccountTelephone":
                setNewAccountPhone(e.target.validity.valid ? e.target.value : newAccountPhone);
                break;
            case "newAccountPassword":
                setNewAccountPassword(e.target.value);
                break;
            case "confirmpassword":
                setConfirmPassword(e.target.value);
                break;
            case "receiveTxtMessage":
                setReceiveTxtMessage(e.target.value);
                break;
            default:
                break;
        }
        
        clearValidationList();   
    }

    /**
     * Show/Hide Password
     *
     *
     */
     const showPassword = () => {
        let passwordInput=$('[name="newAccountPassword"]');
        let passwordIconEyeSlash=$('[name="passwordIconEyeSlash"]');
        let passwordIconEye=$('[name="passwordIconEye"]');
  
        if(passwordInput.attr('type')==='password'){
            passwordInput.attr('type','text');
            passwordIconEyeSlash.show();
            passwordIconEye.hide();
        }else{
           passwordInput.attr('type','password');
           passwordIconEyeSlash.hide();
           passwordIconEye.show();
        }
    }

    /**
     * Show/Hide Confirm Password
     *
     *
     */
     const showConfirmPassword = () => {
        let confirmPasswordInput=$('[name="confirmpassword"]');
        let confirmPasswordIconEyeSlash=$('[name="confirmPasswordIconEyeSlash"]');
        let confirmPasswordIconEye=$('[name="confirmPasswordIconEye"]');
  
        if(confirmPasswordInput.attr('type')==='password'){
            confirmPasswordInput.attr('type','text');
            confirmPasswordIconEyeSlash.show();
            confirmPasswordIconEye.hide();
        }else{
           confirmPasswordInput.attr('type','password');
           confirmPasswordIconEyeSlash.hide();
           confirmPasswordIconEye.show();
        }
    }

    /**
     * Handles onReCaptchaChange for New Account
     */
     const onReCaptchaNewAccountChange = (value) => {
        setReCaptchaNewAccountId(value);
    }

    /**
     * Reset the password for a system account.
     *
     * @param email - The email of the system account to reset the password for.
     */
    const resetPassword = (email) => {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/forgotpassword`, {
            username: email
        }).then(response => {
            setAlertMessage("We've sent you an email with a link to reset your password. Be sure to check your spam folders if you don't see the email.");
        }).catch(error => {
            addErrorToValidationList(error);
        });

    }

    /**
     * setup Noindex tag in head.
     */
    const setupNoindexTag = () => {
        const meta = document.createElement('meta');
        meta.name = `robots`;
        meta.content = `noindex`;
        meta.setAttribute('id', 'meta-searching-index');

        const currentMeta = document.getElementById('meta-searching-index');

        if (currentMeta) {
            document.head.removeChild(currentMeta);
        }
        document.head.appendChild(meta);
    }

    let ScrollLink = Scroll.Link;
    const {formatMessage} = props.intl;
  
    return(
        <div className="content-block">

            <Spinner visible={showSpinner}/>

            <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${onboardingPage && onboardingPage?.customFields?.background ? onboardingPage.customFields.background : modernTower}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                <div className="container main-container">

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-5 col-sm-12">

                            <h1 className="text-white mb-4">
                                {onboardingPage.customFields && onboardingPage.customFields?.heroHeading ? onboardingPage.customFields.heroHeading : ''}
                            </h1>
                            <ul className="fa-ul benefit-list text-white lead mb-5">
                                {onboardingPage && onboardingPage.customFields && onboardingPage.customFields?.heroPoint1 ? (
                                    <li>
                                    <FontAwesomeIcon icon={["far", "check"]} className="fa-li mt-2" />
                                    {onboardingPage.customFields?.heroPoint1}
                                    </li>
                                ) : null}
                                {onboardingPage && onboardingPage.customFields && onboardingPage.customFields?.heroPoint2 ? (
                                    <li>
                                    <FontAwesomeIcon icon={["far", "check"]} className="fa-li mt-2" />
                                    {onboardingPage.customFields?.heroPoint2}
                                    </li>
                                ) : null}
                                {onboardingPage && onboardingPage.customFields && onboardingPage.customFields?.heroPoint3 ? (
                                    <li>
                                    <FontAwesomeIcon icon={["far", "check"]} className="fa-li mt-2" />
                                    {onboardingPage.customFields?.heroPoint3}
                                    </li>
                                ) : null}
                                {onboardingPage && onboardingPage.customFields && onboardingPage.customFields?.heroPoint4 ? (
                                    <li>
                                    <FontAwesomeIcon icon={["far", "check"]} className="fa-li mt-2" />
                                    {onboardingPage.customFields?.heroPoint4}
                                    </li>
                                ) : null}
                            </ul>
                            
                            <div className="mb-5">
                                <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-7 col-sm-12">
                            <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                {
                                    onboardingPage?.customFields?.logo ? (
                                        <img src={onboardingPage?.customFields?.logo} className="rounded img-fluid" alt="Logo" title="Logo" />
                                    ) : (
                                        <h3 className="display-5">
                                            Welcome to LetUs!
                                        </h3>
                                    )
                                }
                                <div>
                                    <span className="small text-muted">{onboardingPage?.customFields?.subheading || 'Please create an account to securely apply.'}</span>
                                </div>
                               
                                <div className="col-12 col-md-12 col-xs-12 pl-md-0 pl-lg-3 pt-4 pb-0">
                                    <ul className="nav nav-tabs border-bottom-0">
                                        <li className="nav-item">
                                            <a className={`nav-link ${selectedTab === 'NEWACCOUNT' ? 'active' : ''}`} id="newaccount-tab" data-toggle="tab" href="#newaccount" role="tab" aria-controls="newaccount" aria-selected="true" onClick={() => selectTab('NEWACCOUNT')}>
                                                New account
                                            </a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className={`nav-link ${selectedTab === 'SIGNIN' ? 'active' : ''}`} id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true" onClick={() => selectTab('SIGNIN')}>
                                                Sign in
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <hr className="mt-0 mb-4" />

                                <Alert validationList={validationList} />                              
                                <div className="tab-content" id="tabs">
                                    <div className={`tab-pane fade-show ${selectedTab === 'NEWACCOUNT' ? 'active' : ''}`} id="newaccount" role="tabpanel" aria-labelledby="newaccount-tab">
                                        <form onSubmit={(event) =>submitNewAccount(event)}> 

                                            <div className="form-group row mb-1">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={['fas', 'user']} />
                                                            </span>
                                                        </div>
                                                        <input name="firstName" type="text" placeholder={formatMessage({ id: "field.firstname"})} maxLength="100" autoFocus={true} value={firstName} onChange={handleNewAccountChange} className="form-control form-control-lg mb-0 rounded-right" />
                                                        <input name="lastName" type="text" placeholder={formatMessage({ id: "field.lastname"})} maxLength="100" value={lastName} onChange={handleNewAccountChange} className="form-control form-control-lg mb-0 rounded ml-2" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mb-1">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={['fas', 'envelope']} />
                                                            </span>
                                                        </div>
                                                        <input name="newAccountUsername" type="email" placeholder={formatMessage({ id: "field.email"})} autoFocus={true} readOnly={lockUsername} value={newAccountUsername} onChange={handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mb-1">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={['fas', 'phone']} />
                                                            </span>
                                                        </div>
                                                        <input name="newAccountTelephone" type="tel" pattern="[0-9]*" maxLength="40" placeholder={formatMessage({ id: "field.phone"})} autoFocus={true}  value={newAccountPhone} onChange={handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mt-0 mb-0">
                                                <div className="card com-sm-12  bg-transparent mx-3 mb-0">
                                                    <div className="card-body py-0">
                                                        <input name="receiveTxtMessage" type="checkbox" value={receiveTxtMessageBool} onChange={handleNewAccountChange} checked={receiveTxtMessageBool || false}/>
                                                        <small className="extra-small text-muted">
                                                            &nbsp;&nbsp;By providing your phone number, you agree to receive text messages from Letus. Message & data rates may apply. Message frequency varies. Reply STOP to opt out, reply HELP for help.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mb-1">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={['fas', 'key']} />
                                                            </span>
                                                        </div>
                                                        <input name="newAccountPassword" type="password" placeholder={formatMessage({ id: "field.password"})} value={newAccountPassword} onChange={handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                        <div className="input-group-append" onClick={() => {showPassword()}}>
                                                            <span className="input-group-text eye-container">
                                                                <FontAwesomeIcon icon={['fal', 'eye']} name="passwordIconEye"/>
                                                                <FontAwesomeIcon icon={['fal', 'eye-slash']} name="passwordIconEyeSlash"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mb-2">
                                                <div className="col-sm-12">
                                                    <div className="input-group input-group-lg">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <FontAwesomeIcon icon={['fas', 'key']} />
                                                            </span>
                                                        </div>
                                                        <input name="confirmpassword" type="password" placeholder={formatMessage({ id: "field.password.confirm"})} value={confirmpassword} onChange={handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                        <div className="input-group-append" onClick={() => {showConfirmPassword()}}>
                                                            <span className="input-group-text eye-container">
                                                                <FontAwesomeIcon icon={['fal', 'eye']} name="confirmPasswordIconEye"/>
                                                                <FontAwesomeIcon icon={['fal', 'eye-slash']} name="confirmPasswordIconEyeSlash"/>
                                                            </span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row mt-0 mb-0">
                                                <div className="card com-sm-12  bg-transparent mx-3 mb-0">
                                                    <div className="card-body py-0">
                                                        <small className="extra-small text-muted">
                                                            By clicking Create Account, you acknowledge you have read and agreed to our <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>.
                                                        </small>  
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col text-center">
                                                    <div className="small mb-1 mt-2 recaptcha-display">
                                                        <ReCAPTCHA
                                                            sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                                                            onChange={onReCaptchaNewAccountChange}
                                                        />    
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0" 
                                                    disabled={reCaptchaNewAccountId !== null && enableCreateAccountNewAccountButton ? false : true}>
                                                        <FormattedMessage id="button.create.account" />
                                                    </button>
                                                    
                                                </div>
                                            </div>

                                            <div className="row justify-content-sm-center mt-3">
                                                <div className="col-md-10 text-center">
                                                    <p className="text-white text-center small text-muted">
                                                        Already have an account?&nbsp; 
                                                        <Link className="text-decoration-none" to="/login">
                                                            <span className="text-primary">Log in now</span>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>

                                            <Modal
                                                id="password-expired"
                                                theme="primary"
                                                iconType="fas"
                                                iconName="key"
                                                title="Password Expired"
                                                body="Your Letus account password has expired. Click the button below to receive an email with a link to reset your password."
                                            >
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                                    <FormattedMessage id="button.close" />
                                                </button>
                                                <button onClick={(newAccountUsername) => {resetPassword(newAccountUsername)}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                    Reset My Password
                                                </button>
                                            </Modal>
                                        </form>
                                    </div>    
                                </div>
                            </div>

                            <div className="row justify-content-sm-center">
                                <div className="col-md-10 text-center">
                                    <p className="text-white text-center small">
                                        
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container">

                <div className="text-center pt-4">
                    <h1 className="py-4">
                        Letus is made for renters, by renters
                    </h1>
                </div>

                <div className="row justify-content-center py-5">

                    <div className="col-md text-center mb-5 mb-md-0">
                        <div className="px-3">
                            <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                            <h3 className="display-5 mb-2">
                                Payments
                            </h3>
                            <p className="">
                                With flexible payment options and recurring schedules, making rent payments is a breeze.
                            </p>
                            <ScrollLink to="payments" smooth={true} duration={1000}>
                                <div className="btn btn-sm btn-outline-primary">
                                    Learn more
                                </div>
                            </ScrollLink>
                        </div>
                    </div>

                    <div className="col-md text-center mb-5 mb-md-0">
                        <div className="px-3">
                            <FontAwesomeIcon icon={['fal', 'bell']} className="fa-fw mb-5 text-primary" size="6x"/>
                            <h3 className="display-5 mb-2">
                                Notifications
                            </h3>
                            <p className="">
                                Receive real-time payment updates and detailed payment receipts, direct to your inbox.
                            </p>
                            <ScrollLink to="notifications" smooth={true} duration={1000}>
                                <div className="btn btn-sm btn-outline-primary">
                                    Learn more
                                </div>
                            </ScrollLink>
                        </div>
                    </div>

                    <div className="col-md text-center mb-5 mb-md-0">
                        <div className="px-3">
                            <FontAwesomeIcon icon={['fal', 'home-lg']} className="fa-fw mb-5 text-primary" size="6x"/>
                            <h3 className="display-5 mb-2">
                                Leases
                            </h3>
                            <p className="">
                                Letus has you covered with support from multiple leases to multiple roommates.
                            </p>
                            <ScrollLink to="leases" smooth={true} duration={1000}>
                                <div className="btn btn-sm btn-outline-primary">
                                    Learn more
                                </div>
                            </ScrollLink>
                        </div>
                    </div>

                    <div className="col-md text-center mb-5 mb-md-0">
                        <div className="px-3">
                            <FontAwesomeIcon icon={['fal', 'gift']} className="fa-fw mb-5 text-primary" size="6x"/>
                            <h3 className="display-5 mb-2">
                                Rewards
                            </h3>
                            <p className="">
                                Take advantage of one of your largest monthly expenses with your favorite rewards card.
                            </p>
                            <ScrollLink to="rewards" smooth={true} duration={1000}>
                                <div className="btn btn-sm btn-outline-primary">
                                    Learn more
                                </div>
                            </ScrollLink>
                        </div>
                    </div>

                </div>

                <hr className="my-5" id="payments" />

                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <h1 className="mb-4">
                            Make paying your rent simple and rewarding
                        </h1>
                        <p className="mb-4 lead">
                            Despise those late fees? With Letus, you'll never miss another payment again with monthly auto payments, using a bank account or rewards credit card that suits you best.
                        </p>
                        <ul className="list-inline small">
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Multiple Payment Methods</span>
                            </li>
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'calendar-check']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Setup Scheduled Payments</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <img src={paymentFlow} alt="Letus offers your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                    </div>
                </div>

                <hr className="my-5" id="notifications" />

                <div className="row">
                    <div className="col-md-6 order-md-2 align-self-center">
                        <h1 className="mb-4">
                            Stay in the loop with real time notifications
                        </h1>
                        <p className="mb-4 lead">
                            Never lose track of your payment history again. Reviewing the details of all your past and upcoming rent payments can be done anytime, anywhere from your Letus dashboard.
                        </p>
                        <ul className="list-inline small">
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'bell']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Real-Time Payment Updates</span>
                            </li>
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Detailed Payment Receipts</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <img src={recurringPayment} alt="Receive email notifications from all of your tenants' payments, even if a payment was failed or charged back, all while funds are deposited directly into your bank account" className="img-sm-fluid float-right" width="130%"/>
                    </div>
                </div>

                <hr className="my-5" id="leases" />

                <div className="row">
                    <div className="col-md-6 align-self-center">
                        <h1 className="mb-4">
                            Supported for multiple leases and roommates
                        </h1>
                        <p className="mb-4 lead">
                            Whether you're paying for your son or daughter's college dorm, or splitting payments for your dorm with your roommate, Letus has you covered no matter the scenario.
                        </p>
                        <ul className="list-inline small">
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'stopwatch']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Track Upcoming Payments</span>
                            </li>
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'home-heart']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Review Lease Details</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <img src={transactions} alt="Letus's intuitive payments platform and user interface will save you time and make paying rent easier than ever before" className="img-sm-fluid my-3" width="130%"/>
                    </div>
                </div>

                <hr className="my-5" id="rewards" />

                <div className="row">
                    <div className="col-md-6 order-md-2 align-self-center">
                        <h1 className="mb-4">
                            Maximize your rewards potential and build your credit
                        </h1>
                        <p className="mb-4 lead">
                            Make your rent payments with your favorite rewards credit card and enjoy the cash-back or points redemption from one of your largest monthly payments, all while building your credit.
                        </p>
                        <ul className="list-inline small">
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'mobile-alt']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Make Payments Anytime</span>
                            </li>
                            <li className="list-inline-item py-1">
                                <FontAwesomeIcon icon={['far', 'gift']} className="fa-fw va-b mr-1 text-primary"/>
                                <span className="mr-2">Earn Credit Card Rewards</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <img src={creditCards} alt="Making rent payments with Letus allows tenants to build their credit history and earn points from their favorite rewards credit card" className="img-sm-fluid float-right" width="130%"/>
                    </div>
                </div>

            </div>

            <div className="jumbotron jumbotron-fluid mb-0">
                <div className="container">

                    <div className="text-center">
                        <h3 className="display-4 py-4">
                            Next steps
                        </h3>
                    </div>

                    <div className="row justify-content-center my-5">

                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Create your account
                                </h3>
                                <p className="">
                                    Set up your Letus tenant account with your rental lease information and payment details.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 text-center mb-5 mb-md-0 border-left border-right">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'check-circle']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Get verified
                                </h3>
                                <p className="">
                                    Your property manager will verify your information and invite you to complete your account.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Make your payments
                                </h3>
                                <p className="">
                                    Start making one time payments, or never miss a payment again with monthly auto payments.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                <div className="container text-center">
                    <h1 className="pt-4 mb-4 text-white">
                        A better rental experience begins with Letus
                    </h1>
                    <div className="row justify-content-md-center">
                        <div className="col-md-8 text-md-center">
                            <p className="lead text-white">
                                
                            </p>
                        </div>
                    </div>
                    <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                        Get Started
                    </ScrollLink>
                </div>
            </div>

            <Modal
                id="info-verify-email"
                theme="primary"
                iconType="fas"
                iconName="exclamation-triangle"
                title="Verify your email address"
                body="The email address has not been verified yet. Please follow the steps in the Verification email we've sent you to verify your account."
            >
                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                    <FormattedMessage id="button.close" />
                </button>
            </Modal>

        </div>
    );
  };

  Landing.propTypes = {
    intl: intlShape.isRequired,
  };
  
  export default injectIntl(Landing);