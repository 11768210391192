import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldPaymentProvider extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for payment providers.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value} required={this.props.required}>
                <option value="">Select a payment provider...</option>
                <option value="PAYSAFE_SPLIT_PAY">Paysafe Split-Pay</option>
                <option value="PAYSAFE_CASH">Paysafe Cash</option>
                <option value="PAYPAL">PayPal</option>
                <option value="GEOSWIFT">Geoswift</option>
                <option value="LOADHUB">Loadhub</option>
                <option value="BLUESNAP">BlueSnap</option>
                <option value="MONEYGRAM">MoneyGram</option>
            </FieldSelect>
        )
    };
}

export default FieldPaymentProvider;
