import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { injectIntl, intlShape } from "react-intl";
import charges from "../../util/charges";
import FieldDate from "./FieldDate";

class FieldOpenCharges extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.handleChangeOpenCharges = this.handleChangeOpenCharges.bind(this);
    }

    /**
     * Handle changes to the selected charges. Handles both the charge code, charge amount and charge dueDate fields
     * for each charge.
     * @param event - The event container.
     */
    handleChangeOpenCharges(event) {

        const parentStateName = this.props.parentStateName;
        let openCharges = parentStateName ? this.props.parent.state[parentStateName] : this.props.parent.state.openCharges;

        let chargeIdSplit = (event.target.name).split("-");
        let chargeIdIndex = chargeIdSplit[chargeIdSplit.length - 1];

        // Handle code changes
        if(chargeIdSplit[1] === 'code') {
            openCharges[chargeIdIndex] = {
                amount: openCharges[chargeIdIndex].amount,
                dueDate: openCharges[chargeIdIndex].dueDate,
                code: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            };
        }

        // Handle amount changes
        if(chargeIdSplit[1] === 'amount') {
            openCharges[chargeIdIndex] = {
                amount: event.target.value,
                dueDate: openCharges[chargeIdIndex].dueDate,
                code: openCharges[chargeIdIndex].code,
                name: openCharges[chargeIdIndex].name
            };
        }

        // Handle dueDate changes
        if(chargeIdSplit[1] === 'dueDate') {
            openCharges[chargeIdIndex] = {
                amount: openCharges[chargeIdIndex].amount,
                dueDate: event.target.value,
                code: openCharges[chargeIdIndex].code,
                name: openCharges[chargeIdIndex].name
            };
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            [parentStateName || openCharges]: openCharges
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the user <to enter a list of charge types and amounts, generally for a
     * payment. Affects the parent's 'charges' state.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                {this.props.openCharges.map((openCharge, key) => {

                    return(
                        <div key={key} className="form-group row mb-2">
                            <div className="col-sm-12">
                                <div className={`input-group input-group-${this.props.size}`}>

                                    <select id={`charge-code-${key}`} name={`charge-code-${key}`} value={openCharge.code} className={`form-control mb-0`} onChange={this.handleChangeOpenCharges} disabled={this.props.disabled}>

                                        <option value="">Select a charge...{this.props.required && '*'}</option>

                                        {this.props.customChargeCodes &&
                                        <React.Fragment>
                                            {this.props.customChargeCodes.filter(customChargeCode => !(customChargeCode.code === openCharge.code)).map((customChargeCode, key) => {
                                                return(
                                                    <option key={key} value={customChargeCode.code}>{customChargeCode.name}</option>
                                                )
                                            })}

                                        return(
                                            {
                                             openCharge.code && <option value={openCharge.code}>{openCharge.name}</option>
                                            }
                                        )

                                        </React.Fragment>
                                        }

                                        {!this.props.customChargeCodes &&
                                        <React.Fragment>
                                            {Object.keys(charges).filter(key => !(key === openCharge.code)).map(key => {
                                                return(
                                                    <option key={key} value={key}>{formatMessage({id: `charge.${key}`})}</option>
                                                )
                                            })}
                                        return(
                                            {
                                             openCharge.code && <option value={openCharge.code}>{openCharge.name}</option>
                                            }
                                        )

                                        </React.Fragment>
                                        }

                                    </select>

                                    <input id={`charge-amount-${key}`} name={`charge-amount-${key}`} value={openCharge.amount || ''} placeholder="0.00" type="number" className={`form-control mb-0`} min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" disabled={this.props.disabled} onChange={this.handleChangeOpenCharges} />

                                    <FieldDate
                                        id='dueDate'
                                        name={`charge-dueDate-${key}`}
                                        model='openCharges'
                                        modelKey={`${key}`}
                                        parent={this.props.parent}
                                        value={openCharge.dueDate || null}
                                        before={new Date(openCharge.dueDate) || null}
                                        disabled={this.props.disabled}
                                        fieldColumns='12'
                                        labelColumns='d-none'
                                    />

                                    {(!this.props.disabled && this.props.removeOpenCharge) &&
                                        <>
                                            <button 
                                                className="btn btn-outline-primary btn-sm react-datepicker-popper" 
                                                type="button" 
                                                title="You must provide at least one charge" 
                                                onClick={() => this.props.removeOpenCharge(key)}>
                                                <FontAwesomeIcon icon={['fas', 'times']} 
                                                className=""/>
                                            </button> {this.props.required && <span> * </span>}
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    );
                })}

                {(!this.props.disabled && this.props.addOpenCharge) &&
                <div className={`btn btn-outline-primary btn-sm ${this.props.openCharges?.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.props.addOpenCharge()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add One-time charge
                </div>
                }

                {(!this.props.disabled && this.props.importCharges) &&
                    <div className={`btn btn-outline-primary btn-sm ml-2 ${this.props.openCharges.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.props.importCharges()}>
                        <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} className="fa-fw"/> Import charges
                    </div>
                }

            </React.Fragment>
        )
    };
}

FieldOpenCharges.propTypes = {
    intl: intlShape.isRequired,
};

FieldOpenCharges.defaultProps = {
    size: 'md'
};

export default injectIntl(FieldOpenCharges);
