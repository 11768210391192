import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import propertii from "../../media/img/logos/logo-letus-dark-new.png";
import * as constants from "../../util/constants";
import { parseDateForPad } from "../../util/dates";
import Propertii from "../common/Propertii";
import Alert from "./Alert";

class ReceiptPaymentTransaction extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyName: 'NA',
            installments: 0,
            paymentDetails: [],
            validationList: []
        }

        this.peekPropertyName = this.peekPropertyName.bind(this);
    }
    
    /**
     * In the case some of the props might get updated later
     */
    componentDidUpdate(prevProps) {
        if (prevProps.paymentTransaction?.propertyId !== this.props.paymentTransaction?.propertyId){
            this.peekPropertyName(this.props.paymentTransaction?.propertyId);  
        }

        if (prevProps.paymentTransaction?.id !== this.props.paymentTransaction?.id) {
            if(this.props.userType === "TYPE_ADMIN" && 
                this.props.paymentTransaction?.paymentMethodType === 'TYPE_INSTALLMENT_PAY' && 
                this.props.paymentTransaction?.joins?.installmentPay){
                this.getInstallmentPayDetails(this.props.paymentTransaction.joins.installmentPay);
            }
        }
    }

    /**
     * Redirect the admin user to the funds transfer component with a modal pre-filled when the user clicks on the funds
     * transfer ID in the payment receipt.
     *
     * @param fundsTransferId - The ID of the funds transfer record selected.
     */
    viewFundsTransfer(fundsTransferId) {

        $('#receipt-payment-transaction').modal('hide');

        this.props.history.push({
            pathname: '/admin/system/efts',
            state: {
                fundsTransferId: fundsTransferId
            }
        });
    }

    formatErrorString(string, paymentProviderId) {
        let myRegexp = null
        if (paymentProviderId === 'PAYPAL') {
            myRegexp = /.*-([^]+)[^:]+/g;
        } else if(paymentProviderId === 'GEOSWIFT') {
            myRegexp = /.*:([^:]+)[^:]+/g;
        } else {
            myRegexp = /.*:([^:]+):[^:]+/g;
        }
        var match = myRegexp.exec(string);
        if (match) {
            if(paymentProviderId === 'BMO' || paymentProviderId === 'BLUESNAP'){
                return string.charAt(0).toUpperCase() + string.slice(1) + '.';
            } else {
                return match[1].charAt(0).toUpperCase() + match[1].slice(1) + '.';
            }

        } else {
            return string.charAt(0).toUpperCase() + string.slice(1) + '.';
        }
    }

    /**
     * Peek property details based on a property id
     * @param propertyId
     */
    peekPropertyName(propertyId) {
        if(!propertyId) {
            return;
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}/peek`, {
        }).then(response => {

            let propertyName = response.data?.propertyName;
            if(propertyName) {
                propertyName += ` ${response.data?.street1}`
            } else {
                propertyName = response.data?.street1;
            }

            this.setState(prevState => ({
                ...prevState,
                propertyName: propertyName,
            }));
        })
        .catch(error => {
            this.handleValidation(error);
        });
    }
    
    /**
     * Get installment pay
     * @param paymentMethodId
     */
    getInstallmentPayDetails(installmentPay) {
        this.setState(prevState => ({
            ...prevState,
            installments: installmentPay.installments,
            paymentDetails: installmentPay.installmentDetails?.paymentDetails ? installmentPay.installmentDetails.paymentDetails : []
        }));
    }

    /**
     * Check wether the HPP has a custom note label for receipt component
     * @param paymentTransaction - Payment transaction object being displayed 
     */
    generateNoteLabel(paymentTransaction){
        if(paymentTransaction.source === 'HOSTED' && this.props.hostedPaymentPage){
            const hpp = this.props.hostedPaymentPage;

            if(hpp.noteLabel !== null && hpp.noteLabel !== ''){
                return hpp.noteLabel;
            } else {
                return 'Note';
            }

        } else {
            return 'Note';
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A payment transaction receipt modal.
     */
    render() {

        return(
            <div className="modal fade" id="receipt-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="receipt-payment-transaction-label" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) ? 'modal-xl' : 'modal-md'}`} role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            {(this.props.userType === 'TYPE_ADMIN' || this.props.userType === 'TYPE_MANAGER') &&
                                <button type="button" className="close text-light" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            }
                            <div className="row">
                                <div className="col order-2">

                                    {this.renderReceiptHeader(this.props.paymentTransaction)}

                                </div>
                                {(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) &&
                                <div className="col-6">

                                    {this.renderReceiptHeader(this.props.parentPaymentTransaction)}

                                </div>
                                }
                            </div>
                        </div>

                        {(this.props.paymentTransaction.integration != null && this.props.paymentTransaction.integration.error != null) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">The integrated system has returned an error: </span>{this.props.paymentTransaction.integration.error}
                            </p>
                        </div>
                        }

                        {(this.props.displayAdvanced && this.props.paymentTransaction.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">The payment provider has returned an error message after attempting to process this transaction: </span>{this.formatErrorString(this.props.paymentTransaction.error, this.props.paymentTransaction.paymentProviderId)}
                            </p>
                        </div>
                        }

                        {(!this.props.displayAdvanced && this.props.paymentTransaction.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                {this.formatErrorString(this.props.paymentTransaction.error, this.props.paymentTransaction.paymentProviderId)} For additional information, please contact our support team at help@let.us.
                            </p>
                        </div>
                        }

                        {(this.props.paymentTransaction.transactionType === 'RETURN' || 
                            this.props.paymentTransaction.transactionType === 'CHARGEBACK') &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">
                                    {this.props.paymentTransaction.transactionType === 'RETURN' ? 'This transaction was returned for the following reason: ' : 'Chargeback Reason: '} 
                                </span>{this.props.paymentTransaction.returnReason}
                            </p>
                        </div>
                        }

                        {(this.state.validationList[0]) &&
                            <React.Fragment>
                                <Alert validationList={this.state.validationList} />
                            </React.Fragment>
                        }

                        <div className={`row ${this.props.paymentTransaction.parentId ? 'no-gutters' : ''}`}>
                            <div className="col order-1">
                                <div className="modal-body modal-body-table">

                                    {this.renderReceiptBody(this.props.paymentTransaction)}
                                    
                                    {
                                        (this.props.userType === "TYPE_ADMIN" && this.props.paymentTransaction.paymentMethodType === 'TYPE_INSTALLMENT_PAY') && (
                                            <>
                                                <p className="text-center mb-0">{ `${this.state.installments} Total Installment${this.state.installments > 1 ? 's' : ''}`}</p>
                                                <table className="table border-left border-right">
                                                    <tbody>
                                                        <tr className="small">
                                                            <td className="font-weight-bold">#</td> 
                                                            <td className="text-center font-weight-bold">Amount</td> 
                                                            <td className="text-center font-weight-bold">Date</td>
                                                            <td className="text-right font-weight-bold">Paid</td>
                                                        </tr>
                                                        {this.state?.paymentDetails &&
                                                            this.state.paymentDetails.map((installment, i) => (
                                                                <tr className="small">
                                                                    <td>{i+1}</td> 
                                                                    <td className="text-center">
                                                                        <FormattedNumber value={installment.amount} style={`currency`} currency={this.props.paymentTransaction.currency || 'USD'} />
                                                                    </td> 
                                                                    <td className="text-center">
                                                                        <Moment format="MMM DD, YYYY HH:mm">
                                                                            {installment.paymentDueDate}
                                                                        </Moment>
                                                                    </td>
                                                                    <td className="text-right">{installment.status === "PAID" ? '✔️' : ''}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                            {(this.props.paymentTransaction.parentId && this.props.parentPaymentTransaction) &&
                            <div className="col-6">
                                <div className="modal-body modal-body-table">

                                    {this.renderReceiptBody(this.props.parentPaymentTransaction)}

                                </div>
                            </div>
                            }
                        </div>

                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                            <div className="receipt-actions">
                                {this.props.children}
                            </div>

                            <div className="receipt-disclaimer d-none">
                                <p className="my-2">Have any questions? Send us an email at <a href="mailto:help@rentmoola.com">help@rentmoola.com</a>.</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    };

    /**
     * Render the header portion of the receipt.
     *
     * @param paymentTransaction - The payment transaction being viewed.
     * @returns {*} - The header portion of the receipt.
     */
    renderReceiptHeader(paymentTransaction) {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <div className="receipt-logo d-none">
                    <img src={propertii} alt="Letus" className="img-fluid w-50" />
                </div>
                <div className="receipt-icon">
                    {paymentTransaction.transactionType &&
                    <FontAwesomeIcon icon={["fas", formatMessage({id: "enum.transactionType." + paymentTransaction.transactionType + ".icon"})]} className="fa-fw va-b mb-3" size="4x" />
                    }
                </div>
                <h5 className="modal-title" id={`receipt-payment-transaction-label`}>
                    {paymentTransaction.transactionType && paymentTransaction.source !== 'FUNDING' &&
                    <div className="">
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} /> Receipt
                    </div>
                    }
                    {paymentTransaction.source === 'FUNDING' &&
                        <div className="">
                            <FormattedMessage id={`enum.transactionSource.${paymentTransaction.source}`} /> Receipt
                        </div>
                    }
                </h5>
                <p className="mb-0 small">
                    {paymentTransaction.transactionType &&
                    <span className="">
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} /> made on {" "}
                            <Moment format="MMM DD, YYYY">
                                { paymentTransaction?.source === "PAD" && paymentTransaction?.transactionType === 'PAYMENT'
                                ? parseDateForPad(paymentTransaction.createDate)
                                : paymentTransaction.createDate}
                            </Moment>
                    </span>
                    }
                </p>

            </React.Fragment>
        );
    }

    /**
     * Fetch billing account
     */
    fetchBillingAccount(paymentTransaction) {
        if(paymentTransaction?.billingAccountType && paymentTransaction?.billingAccountId){

            axios.post(`${constants.REACT_APP_HOST_API_URL}/${paymentTransaction?.billingAccountType.replace('TYPE_', '')}/search`, {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'id',
                        operator: 'EQUALS',
                        fieldValue: paymentTransaction.billingAccountId
                    }
                ],
                joins: {

                    company: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['name', 'status']
                    }
                }
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                if(response.data?.records?.length) {

                    $('#receipt-payment-transaction').modal('hide');

                    this.props.history.push({
                        pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${paymentTransaction.billingAccountId}/edit`
                    });

                } else {

                    this.setState({
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'danger',
                                code: 'common.documents.created',
                                message: `This tenant cannot be accessed because they belong to ${paymentTransaction.joins.co.name} that has been retired.`
                            }
                        }],
                    });
                }

            }).catch(error => {

                this.handleValidation(error);
            });

        } else {

            $('#receipt-payment-transaction').modal('hide');

            this.props.history.push({
                pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${paymentTransaction.billingAccountId}/edit`
            });
        }
    }

    /**
     * Render the body portion of the receipt.
     *
     * @param paymentTransaction - The payment transaction being viewed.
     * @returns {*} - The body portion of the receipt.
     */
    renderReceiptBody(paymentTransaction) {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <table className={`table mb-0 ${paymentTransaction.parentId ? 'border-left' : ''}`}>
                <tbody>
                {!paymentTransaction.invoiceId &&
                <React.Fragment>
                    {(paymentTransaction.charges && paymentTransaction.charges.length > 0) &&
                    <React.Fragment>
                        {paymentTransaction.charges.map((data, key) => {
                            return (
                                <tr key={key}>
                                    <td className="">
                                        <div className="">
                                            <FormattedMessage id={data.name ? data.name : data.code} />
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <FormattedNumber value={data.amount} style={`currency`} currency={paymentTransaction.currency || 'USD'}/>
                                    </td>
                                </tr>
                            );
                        })}
                    </React.Fragment>
                    }
                </React.Fragment>
                }
                {paymentTransaction.invoice &&
                <tr>
                    <td className="">
                        <div className="">
                            Invoice {paymentTransaction.invoice.invoiceNumber}
                        </div>
                    </td>
                    <td className="text-right">
                        <FormattedNumber value={paymentTransaction.invoice.grandTotal} style={`currency`} currency={paymentTransaction.invoice.currency || 'USD'}/>
                    </td>
                </tr>
                }
                {((paymentTransaction.transactionType === 'CHARGEBACK' || paymentTransaction.transactionType === 'CHARGEBACK_REVERSAL') && paymentTransaction.amount !== 0) &&
                <tr>
                    <td className="">
                        Charges
                    </td>
                    <td className="text-right text-danger">
                        {(paymentTransaction.amount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency || 'USD'}/>
                        }
                    </td>
                </tr>
                }
                {(paymentTransaction.transactionType === 'REFUND' && paymentTransaction.amount !== 0) &&
                <tr>
                    <td className="">
                        Refunded Charges
                    </td>
                    <td className="text-right text-danger">
                        {(paymentTransaction.amount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency || 'USD'}/>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayFeeAmount && paymentTransaction.feeAmount !== 0) &&
                <tr>
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded Transaction Fee' : 'Transaction Fee'}
                    </td>
                    <td className={`text-right ${paymentTransaction.transactionType === 'REFUND' ? 'text-danger' : ''}`}>
                        {(paymentTransaction.feeAmount != null && paymentTransaction.currency) &&
                        <FormattedNumber value={(paymentTransaction.feePaidByCompany || paymentTransaction?.feeType === 'SUBTRACT')? 0 : paymentTransaction.feeAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'}/>
                        }
                        {(this.props.displayAdvanced && paymentTransaction.feeProfile) &&
                        <span tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-html="true" title={paymentTransaction.feeProfile.companyId ? 'Custom Transaction Fee Details' : 'Default Transaction Fee Details'} data-content={
                            `
                                Payment Type: <span className=''>${paymentTransaction.feeProfile.paymentType}</span><br />
                                Card Type: <span className=''>${paymentTransaction.feeProfile.cardType ? paymentTransaction.feeProfile.cardType : 'Any'}</span><br />
                                Country: <span className=''>${formatMessage({id: "country." + paymentTransaction.feeProfile.country})}</span><br />
                                Fee Rate: <span className=''>${paymentTransaction.feeProfile.feeType === 'SUBTRACT' ? '-' : ''}${paymentTransaction.feeProfile.rateType === 'FLAT' ? '$' : ''}${(paymentTransaction.feeProfile.feeRate).toFixed(2)}${paymentTransaction.feeProfile.rateType === 'PERCENT' ? '%' : ''}</span><br />
                                International Rate: <span className=''>${paymentTransaction.feeProfile.feeType === 'SUBTRACT' ? '-' : ''}${paymentTransaction.feeProfile.internationalRateType === 'FLAT' ? '$' : ''}${(paymentTransaction.feeProfile.internationalFeeRate).toFixed(2)}${paymentTransaction.feeProfile.internationalRateType === 'PERCENT' ? '%' : ''}</span><br />
                                `
                        }>
                                <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 fa-fw small btn-link c-pointer" />
                            </span>
                        }
                    </td>
                </tr>
                }
                <tr>
                    <td className="font-weight-bold">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded Total' : 'Total'}
                    </td>
                    {this.props.displayFeeAmount &&
                    <td className={`font-weight-bold text-right ${(paymentTransaction.amount + paymentTransaction.feeAmount < 0) ? 'text-danger' : ''}`}>
                        {((paymentTransaction.amount && paymentTransaction.currency)) && (paymentTransaction.feePaidByCompany || paymentTransaction.feeAmount == null) ?
                            <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                            :
                            <FormattedNumber value={paymentTransaction.amount + paymentTransaction.feeAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                        }
                    </td>
                    }
                    {!this.props.displayFeeAmount &&
                    <td className={`font-weight-bold text-right ${paymentTransaction.amount < 0 ? 'text-danger' : ''}`}>
                        {(paymentTransaction.amount && paymentTransaction.currency) &&
                        <FormattedNumber value={paymentTransaction.amount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                        }
                    </td>
                    }
                </tr>
                {paymentTransaction.reversedAmount > 0 &&
                <tr>
                    <td className="">
                        Reversed Charges
                    </td>
                    <td className="text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                    </td>
                </tr>
                }
                {(this.props.displayFeeAmount && paymentTransaction.reversedFeeAmount > 0) &&
                <tr>
                    <td className="">
                        Reversed Transaction Fee
                    </td>
                    <td className="text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedFeeAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                    </td>
                </tr>
                }
                {(paymentTransaction.reversedAmount > 0 || paymentTransaction.reversedFeeAmount > 0) &&
                <tr>
                    <td className="font-weight-bold">
                        Reversed Total
                    </td>
                    {this.props.displayFeeAmount &&
                    <td className="font-weight-bold text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount + -paymentTransaction.reversedFeeAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                    </td>
                    }
                    {!this.props.displayFeeAmount &&
                    <td className="font-weight-bold text-right text-danger">
                        <FormattedNumber value={-paymentTransaction.reversedAmount} style={`currency`} currency={paymentTransaction.currency || 'USD'} />
                    </td>
                    }
                </tr>
                }
                {(paymentTransaction.joins && paymentTransaction.joins.c) &&
                <tr className="small">
                    <td className="">
                        Paid By
                    </td>
                    <td className="text-right" onClick={() => this.fetchBillingAccount(paymentTransaction)}>
                        {this.props.userType &&
                        (
                            paymentTransaction.billingAccountId ?
                            <div className="btn-link c-pointer" onClick={() => this.fetchBillingAccount(paymentTransaction)}>
                                {paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}
                            </div>
                            : <>{paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}</>
                        )
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                {(paymentTransaction.joins && (paymentTransaction.joins.m || paymentTransaction.joins.pm)) &&
                <tr className="small">
                    <td className="">
                        Paid To
                    </td>
                    <td className="text-right">
                        {this.props.userType &&
                        (
                            paymentTransaction.billingAccountId ?
                            <Link to={`/${this.props.userType.substring(5).toLowerCase()}/managers/${paymentTransaction.joins.m?.id || paymentTransaction.joins.pm?.id}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                                {paymentTransaction.joins.m?.firstName || paymentTransaction.joins.pm?.firstName} {paymentTransaction.joins.m?.lastName || paymentTransaction.joins.pm?.lastName}
                            </Link>
                            : <>{paymentTransaction.joins.m?.firstName || paymentTransaction.joins.pm?.firstName} {paymentTransaction.joins.m?.lastName || paymentTransaction.joins.pm?.lastName}</>
                        )
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.c.firstName} {paymentTransaction.joins.c.lastName}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                {paymentTransaction.paymentMethodType === 'TYPE_DEFERRED' && this.props.userType === 'TYPE_ADMIN' ?
                <tr className="small">
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded To' : 'Paid With'}
                    </td>
                    <td className="text-right">
                        <div className="">
                                {'Default'}
                        </div>
                    </td>
                </tr>
                : <>
                {paymentTransaction.paymentMethodType !== 'TYPE_DEFERRED' ?
                <tr className="small">
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded To' : 'Paid With'}
                    </td>
                    <td className="text-right">
                        {paymentTransaction.paymentMethodType === 'TYPE_CREDIT_CARD' &&
                        <div className="">
                            {paymentTransaction.cardBrand ? formatMessage({id: "enum.creditCard.brand." + paymentTransaction.cardBrand}) : 'Credit Card'} {paymentTransaction.last4 ? ' ending in ' + paymentTransaction.last4 : ''}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_BANK_ACCOUNT' &&
                        <div className="">
                            {'Bank Account'} {paymentTransaction.last4 ? ' ending in ' + paymentTransaction.last4 : ''}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_CASH' &&
                        <div className="">
                            {'Cash'}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_PAY_PAL' &&
                        <div className="">
                            {'PayPal'}
                        </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_INSTALLMENT_PAY' &&
                            <div className="">
                                {'Letus Split via '} {paymentTransaction.cardBrand ? formatMessage({id: "enum.creditCard.brand." + paymentTransaction.cardBrand}) : 'Credit Card'} {paymentTransaction.last4 ? ' ending in ' + paymentTransaction.last4 : ''}
                            </div>
                        }
                        {paymentTransaction.paymentMethodType === 'TYPE_WALLET' &&
                            <div className="">
                                {'Wallet'}
                            </div>
                        }
                    </td>
                </tr>
                : null
                }
                </>
                }
                <tr className="small">
                    <td className="">
                        {paymentTransaction.transactionType === 'REFUND' ? 'Refunded On' : 'Created'}
                    </td>
                    <td className="text-right">
                        <Moment format="MMM DD, YYYY HH:mm">
                            { paymentTransaction?.source === "PAD" && paymentTransaction?.transactionType === 'PAYMENT'
                            ? parseDateForPad(paymentTransaction.createDate)
                            : paymentTransaction.createDate}
                        </Moment>
                    </td>
                </tr>
                <tr className="small">
                    { (paymentTransaction?.joins?.pl?.unit || paymentTransaction.customUnit) &&
                    <>
                        <td className="">
                            Unit
                        </td>
                        <td className="text-right">
                            {paymentTransaction?.joins?.pl?.unit ? paymentTransaction.joins.pl.unit : paymentTransaction.customUnit}
                        </td>
                    </>
                    }
                </tr>
                {paymentTransaction.joins && paymentTransaction.joins.p ?
                <tr className="small">
                    <td className="">
                        Property
                    </td>
                    <td className="text-right">
                        {(this.props.userType && this.props.userType !== 'TYPE_ADMIN') &&
                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/properties/${paymentTransaction.propertyId}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                            {paymentTransaction.joins.p.street1}
                        </Link>
                        }
                        {(this.props.userType && this.props.userType === 'TYPE_ADMIN') &&
                        <React.Fragment>
                            {paymentTransaction.joins.p.street1}
                        </React.Fragment>
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.p.street1}
                        </React.Fragment>
                        }
                    </td>
                </tr> : 
                paymentTransaction?.propertyId &&
                <tr className="small">
                    <td className="">
                        Property
                    </td>
                    <td className="text-right">
                        {this.state.propertyName}
                    </td>
                </tr> 
                }
                {(this.props.displayAdvanced && paymentTransaction.joins && paymentTransaction.joins.co) &&
                <tr className="small">
                    <td className="">
                        {paymentTransaction.joins.co.landlordId &&
                        <span className="">Landlord</span>
                        }
                        {!paymentTransaction.joins.co.landlordId &&
                        <span className="">Company</span>
                        }
                    </td>
                    <td className="text-right">
                        {this.props.userType &&
                        <React.Fragment>
                            <Link to={`/${this.props.userType.substring(5).toLowerCase()}/${paymentTransaction.joins.co.landlordId ? 'landlords' : 'companies'}/${paymentTransaction.joins.co.landlordId ? paymentTransaction.joins.co.landlordId : paymentTransaction.companyId}/edit`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                                {paymentTransaction.joins.co.name}
                            </Link>
                        </React.Fragment>
                        }
                        {!this.props.userType &&
                        <React.Fragment>
                            {paymentTransaction.joins.co.name}
                        </React.Fragment>
                        }
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.currency) &&
                <tr className="small">
                    <td className="">
                        Currency
                    </td>
                    <td className="text-right">
                        {paymentTransaction.currency}
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.source) &&
                <tr className="small">
                    <td className="">
                        Initiated Via
                    </td>
                    <td className="text-right">
                        <FormattedMessage id={`enum.transactionSource.${paymentTransaction.source}`} />
                    </td>
                </tr>
                }
                <tr className="small">
                    <td className="">
                        Transaction Type
                    </td>
                    <td className="text-right">
                        {paymentTransaction.transactionType &&
                        <FormattedMessage id={`enum.transactionType.${paymentTransaction.transactionType}`} />
                        }
                    </td>
                </tr>
                {paymentTransaction.status &&
                <tr className="small">
                    <td className="">
                        Transaction Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + paymentTransaction.status + ".class"})}`}/>
                            <span className="ml-1"><FormattedMessage id={`enum.transactionStatus.${paymentTransaction.status}`}/></span>
                        </div>
                    </td>
                </tr>
                }
                {paymentTransaction.payoutStatus &&
                <tr className="small">
                    <td className="">
                        Payout Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.payoutStatus." + paymentTransaction.payoutStatus + ".class"})}`}/>
                            <span className="ml-1"><FormattedMessage id={`enum.transactionStatus.${paymentTransaction.payoutStatus}`}/></span>
                        </div>
                    </td>
                </tr>
                }
                {paymentTransaction.integration != null &&
                <tr className="small">
                    <td className="">
                        Push Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.pushStatus." + paymentTransaction.integration.pushStatus + ".class"})}`} />
                            <span className="ml-1"><FormattedMessage id={`enum.pushStatus.${paymentTransaction.integration.pushStatus}`} /></span>
                        </div>
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.disbursementStatus) &&
                <tr className="small">
                    <td className="">
                        Disbursement Status
                    </td>
                    <td className="text-right">
                        <div className="text-nowrap">
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.disbursementStatus." + paymentTransaction.disbursementStatus + ".class"})}`} />
                            <span className="ml-1"><FormattedMessage id={`enum.disbursementStatus.${paymentTransaction.disbursementStatus}`} /></span>
                        </div>
                    </td>
                </tr>
                }
                <tr className="small">
                    <td className="">
                        Settlement Date
                    </td>
                    <td className="text-right">
                        {paymentTransaction.settlementDate 
                            ?
                                <Moment format="MMM DD, YYYY HH:mm">
                                    {paymentTransaction.settlementDate}
                                </Moment>
                            :
                            <div className="text-nowrap">
                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${(paymentTransaction.status === 'FAILED' || paymentTransaction.status === 'CANCELLED') ? 'text-light': 'text-warning'}`} />
                                <span className="ml-1">{(paymentTransaction.status === 'FAILED' || paymentTransaction.status === 'CANCELLED') ? 'N/A' : 'Settlement Pending'}</span>
                            </div>  
                        }
                    </td>
                </tr>
                {(this.props.displayAdvanced && paymentTransaction.paymentProviderId) &&
                <tr className="small">
                    <td className="">
                        Payment Provider
                    </td>
                    <td className="text-right">
                        <FormattedMessage id={"enum.paymentProviderId." + paymentTransaction.paymentProviderId} />
                    </td>
                </tr>
                }
                {this.props.userType === "TYPE_ADMIN" && paymentTransaction.note &&
                <tr className="small">
                    <td className="">
                        {this.generateNoteLabel(paymentTransaction)}
                    </td>
                    <td className="text-right">
                        {paymentTransaction.note}
                    </td>
                </tr>
                }
                {paymentTransaction.confirmationId &&
                <tr className="small">
                    <td className="">
                        Confirmation ID
                    </td>
                    <td className="text-right">
                        {paymentTransaction.confirmationId}
                    </td>
                </tr>
                }
                {paymentTransaction.compressedConfirmationId &&
                <tr className="small">
                    <td className="">
                        Compressed ID
                    </td>
                    <td className="text-right">
                        {paymentTransaction.compressedConfirmationId}
                    </td>
                </tr>
                }
                {(this.props.displayAdvanced && paymentTransaction.fundsTransferId) &&
                <tr className="small">
                    <td className="">
                        Funds Transfer ID
                    </td>
                    <td className="text-right">
                        <div className="btn-link c-pointer" onClick={() => this.viewFundsTransfer(paymentTransaction.fundsTransferId)}>{paymentTransaction.fundsTransferId}</div>
                    </td>
                </tr>
                }
                {this.props.displayAdvanced &&
                    <>
                        <tr className="small">
                            <td className="">
                                Internal ID
                            </td>
                            <td className="text-right">
                                {paymentTransaction.id}
                            </td>
                        </tr>
                        {paymentTransaction.merchantAccountId &&
                            <tr className="small">
                                <td className="">
                                    Internal MID
                                </td>
                                <td className="text-right">
                                    <a 
                                        href={`admin/companies/${paymentTransaction.companyId}/merchants/${paymentTransaction.merchantAccountId}`}
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                            {paymentTransaction.merchantAccountId}
                                    </a>
                                </td>
                            </tr>
                        }
                    </>
                }
                </tbody>
            </table>
        );

    }
}

ReceiptPaymentTransaction.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReceiptPaymentTransaction);
