import React, { Component } from 'react';

class Spinner extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A standard loading spinner that disables any user clicks on the site until it is hidden. Used for
     * endpoint calls that make take several seconds to return a response.
     */
    render() {

        return(
            <div id={this.props.id} className={`loading-spinner ${this.props.visible ? '' : 'hidden'}`}>

                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                {this.props.message &&
                <div className="pt-5">
                    <p className="text-center text-white pt-4">
                        {this.props.message}
                    </p>
                </div>
                }

            </div>
        )
    };
}

Spinner.defaultProps = {
    id: '',
    visible: false,
};

export default Spinner;