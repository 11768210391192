import $ from 'jquery';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

class FieldPhone extends Component {
  /**
   * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
   * custom handleChange function appears in the parent class, utilize the default handleChange function below.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    if (this.props.parent.handleChange) {
      this.handleChange = this.props.parent.handleChange.bind(this);
    } else {
      this.handleChange = this.handleChange.bind(this);
    }
  }

  /**
   * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
   * state, as opposed to a standalone element.
   *
   * @param value - The new value of the field.
   */
  handleChange(value) {
    let field;

    if (this.props.model !== null) {
      field = this.props.parent.state[this.props.model];
      field[this.props.id] = value;

      if (
        (this.props.parent.state[this.props.model][this.props.id] !==
          this.props.parent.state.customerPhoneOnPageLoad &&
          typeof this.props.parent.state[this.props.model][this.props.id] !== 'undefined') ||
        typeof value !== 'undefined'
      ) {
        this.props.parent.setState((prevState) => ({
          ...prevState,
          [this.props.model]: field,
        }));
      }
    } else {
      this.props.parent.setState((prevState) => ({
        ...prevState,
        [this.props.id]: value,
      }));
    }
  }

  /**
   * Render the component.
   *
   * @returns {*} - A generic phone number field component.
   */
  render() {
    let isInvalid = false;
    let errorCode = '';

    Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
      if (validation.fields) {
        Object.entries(validation.fields).forEach(([key, fieldError]) => {
          if (key === this.props.id) {
            isInvalid = true;
            errorCode = fieldError;
          }
        });
      }
    });

    $(function () {
      $('[data-toggle="popover"]').popover();
    });

    return (
      <React.Fragment>
        <div className={`form-group row ${this.props.containerClass}`}>
          <label
            className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`}
            htmlFor={this.props.id}
          >
            {this.props.label}
            {this.props.optional && <small className='text-muted font-italic'>(Optional)</small>}
            {this.props.required && !this.props.leaseApplicationPage && (
              <small className='text-muted font-italic'>*</small>
            )}
          </label>

          <div className={`col-sm-${this.props.fieldColumns}`}>
            <div className='input-group'>
              {this.props.phoneStatus && (
                <span
                  className={`btn btn-sm mr-1 mt-0.5 mw-50 ${
                    this.props.phoneStatus === 'VALID' ? 'btn-success' : 'btn-danger'
                  }`}
                >
                  {this.props.phoneStatus === 'VALID' ? 'Valid' : 'Invalid'}
                </span>
              )}
              {this.props.phoneStatus === null && (
                <span className='btn btn-light btn-sm disabled mr-1 mt-0.5 mw-50'>
                  Validity - Unknown
                </span>
              )}
              <PhoneInput
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                value={this.props.value || ''}
                required={this.props.required}
                className={`form-control d-inline ${this.props.fieldClass} ${
                  isInvalid ? 'is-invalid' : ''
                } ${this.props.disabled ? 'DisabledInputField' : ''}`}
                onChange={this.props.handleChange || this.handleChange}
              />
            </div>
            {this.props.help && <small className='form-text text-muted'>{this.props.help}</small>}

            {isInvalid && (
              <div className='invalid-feedback'>
                <FormattedMessage id={'danger.' + errorCode} />
              </div>
            )}
            {this.props.phoneStatus === null ? (
              <small className='form-text text-muted'>
                {`Number has not been validated to ensure its a mobile number.`}
              </small>
            ) : this.props.phoneStatus === 'VALID' ? (
              <small className='form-text text-muted'>
                {`This is a mobile number based in US or CA.`}
              </small>
            ) : (
              this.props.phoneStatus && (
                <small className='form-text text-muted'>
                  {`Number cannot receive text messages.`}
                </small>
              )
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FieldPhone.defaultProps = {
  formGroup: true,
  labelColumns: '3',
  fieldColumns: '9',
  labelClass: 'col-form-label-sm',
  fieldClass: 'form-control-sm',
  type: 'text',
  maxLength: '100',
  disabled: false,
};

export default FieldPhone;
