import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from '../../media/img/logos/logo-letus-colour-white-text-new.png';
import * as constants from "../../util/constants";
import FieldText from "./FieldText";
import NavItem from "./NavItem";
import Propertii from "./Propertii";
import Spinner from "./Spinner";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            enableFRLocalizationOption:  false,
            validationList: [],
        };

        this.stopMimic = this.stopMimic.bind(this);
        this.hideBroadcastMessage = this.hideBroadcastMessage.bind(this);
    }

    componentDidMount() {
        this.handleToggleFRLocalizationOption();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location?.pathname !== prevProps.location?.pathname) {
            this.handleToggleFRLocalizationOption();
        }
    }

    /**
     * Hide or show the FR localization option based on user's current path
     */
    handleToggleFRLocalizationOption() { 
        const currentPath = this.props.location?.pathname;
        let enableFROption = false;
        if (!this.validateToken()){
            enableFROption = true;
        }
        switch(currentPath) {
            case "/":
            case "/about":
            case "/landlords":
            case "/tenants":
            case "/contact":
            case "/login":
            case "/pricing":
            case "/logout":
            case "/privacy" :
                enableFROption = true;
                break;
            default:
                break;
        }

        if(enableFROption !== this.state.enableFRLocalizationOption) {
            this.setState({
                enableFRLocalizationOption: enableFROption
            });
        } 
        if(!enableFROption && this.props.locale !== 'en') {
            this.props.handleChangeLocale(null, 'en')
        }
    }

    /**
     * Validate whether or not the token passed in through the props is valid based on a comparison between the current
     * time and the token's expiry time. If the current time is less than the expiry time, the token is valid, and
     * active session components of the navigation bar, such as a log out link and the menu bar, can be displayed. If
     * the token is invalid, public components of the navigation bar can be displayed, such as a log in button.
     *
     * @returns {boolean} - True of false if the session token is valid. If there is no token available, return false.
     */
    validateToken() {

        let token = this.props.token;
        // let userSession;
        // let base64Url;
        // let base64;

        let dateNow;
        let dateExp;
        
        if(token !== null) {

            // base64Url = token.split('.')[1];
            // base64 = base64Url.replace('-', '+').replace('_', '/');
            // userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));

            // dateNow = Math.floor(Date.now()/1000);
            // dateExp = userSession.exp;
           
            dateNow = Date.now();
            dateExp = Number(localStorage.getItem('expires_at') || 0);
        }

        if(token !== null && dateNow < dateExp) {
            return true;
        }

        return false;
    }

    /**
     * Stop mimicking a user. Calls an endpoint which sets the session owner and session role as the logged in
     * administrator user, thus ending the mimic.
     */
    stopMimic() {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/refreshtoken`, {
            headers: this.generateRequestHeaders(),
            params: {
                stopMimic: true
            }
        }).then(response => {
            // Clear broadcast messages picked up from the mimic
            localStorage.removeItem('broadcast_messages');

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
            localStorage.removeItem('mimic_tenant_property_lease_id');
            sessionStorage.removeItem('session_role_id');

            this.props.refreshAppState();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Hide a particular broadcast message by storing the broadcast message's view status in a cookie. Hides the
     * broadcast message for a week, assuming the broadcast message still exists by then.
     *
     * @param broadcastMessageId - The ID of the broadcast message.
     */
    hideBroadcastMessage(broadcastMessageId) {

        const cookies = new Cookies();

        cookies.set('hideBroadcastMessage_' + broadcastMessageId, true, { maxAge: 604800 });

        this.setState(prevState => ({
            ...prevState,
            hideBroadcastMessage: cookies.get('hideBroadcastMessage_' + broadcastMessageId)
        }));

    }

    /**
     * Render the component.
     *
     * @returns {*} - A navigation bar that is always displayed at the top of the application.
     */
    render() {

        const {formatMessage} = this.props.intl;
        const cookies = new Cookies();

        let broadcastMessages = JSON.parse(localStorage.getItem('broadcast_messages'));

        let token = this.props.token;

        let userSession;
        let sessionRole;
       
        if(token !== null && token !== 'undefined') {

            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
            sessionRole = JSON.parse(userSession.sessionRole);

        }

        let logoRedirectPath = this.handleLoginRedirect(sessionRole);

        if(logoRedirectPath == null) {
            logoRedirectPath = "/";
        }

        // Handle closing the navbar on mobile devices when a nav item is selected
        $('.navbar-collapse a').click(function (e) {
            if($('.navbar-toggler').css('display') === 'block' && !$(this).siblings().length) {
                $('.navbar-collapse').collapse('toggle');
            }
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                {(userSession != null && sessionRole != null) && (userSession.ownerId !== sessionRole.id) &&
                <nav className="navbar bg-warning sticky-top shadow">
                    <div className="container-fluid pl-0 pr-0 py-2">
                        <div className="row align-items-center w-100">
                            <div className="col-10">
                                <p className="mb-0">
                                    <FontAwesomeIcon icon={['fas', 'street-view']} className="fa-fw" /> You are currently mimicking {sessionRole.firstName} {sessionRole.lastName}.
                                </p>
                            </div>
                            <div className="col-2 pr-0">
                                <div className="text-right">
                                    {sessionRole.type === 'TYPE_CUSTOMER' &&
                                    <Link to={localStorage.getItem('mimic_tenant_property_lease_id') !== "undefined" ? `/admin/tenants/${localStorage.getItem('mimic_tenant_property_lease_id')}/edit` : `/admin/system/accounts`} className="btn btn-md btn-secondary" onClick={() => this.stopMimic()}>
                                        Stop Mimicking
                                    </Link>
                                    }
                                    {sessionRole.type !== 'TYPE_CUSTOMER' &&
                                    <Link to={`/admin/${sessionRole.type.substring(5).toLowerCase()}s/${sessionRole.id}/edit`} className="btn btn-md btn-secondary" onClick={() => this.stopMimic()}>
                                        Stop Mimicking
                                    </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                }

                {broadcastMessages &&
                <React.Fragment>
                    {broadcastMessages.map((data, key) => {

                        let severity = data.severity;
                        let backgroundColor;
                        let buttonColor;
                        let textColor;

                        switch (severity) {

                            case 'CRITICAL':
                                backgroundColor = 'bg-danger';
                                buttonColor = 'btn-outline-secondary';
                                textColor = 'text-white';
                                break;

                            case 'WARNING':
                                backgroundColor = 'bg-warning';
                                buttonColor = 'btn-outline-dark';
                                textColor = 'text-dark';
                                break;

                            case 'INFO':
                                backgroundColor = 'bg-primary';
                                buttonColor = 'btn-outline-secondary';
                                textColor = 'text-white';
                                break;

                            default:
                                backgroundColor = 'bg-dark';
                                buttonColor = 'btn-outline-secondary';
                                textColor = 'text-white';

                        }

                        if(!cookies.get('hideBroadcastMessage_' + data.id)) {
                            return (
                                <nav className={`navbar ${backgroundColor} sticky-top shadow`} key={key}>
                                    <div className="container-fluid pl-0 pr-0 py-2">
                                        <div className="row align-items-center w-100">
                                            <div className="col-11">
                                                <p className={`mb-0 ${textColor}`}>
                                                    <FontAwesomeIcon icon={['fas', 'bullhorn']} className="fa-fw va-b" /> {data.message}
                                                </p>
                                            </div>
                                            <div className="col-1 pr-0">
                                                <div className="text-right">
                                                    <div className={`btn btn-md ${buttonColor}`} onClick={() => this.hideBroadcastMessage(data.id)}>
                                                        Hide
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            );
                        }

                        return null;

                    })}
                </React.Fragment>
                }

                <nav className="navbar navbar-expand-lg navbar-dark bg-black justify-content-between">

                    <Link to={logoRedirectPath} className="navbar-brand">
                        <img src={logo} height="60" className="d-inline-block align-top" alt={formatMessage({ id: "global.company"})} />
                    </Link>

                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label={formatMessage({ id: "navigation.toggle"})}>
                        <span className="navbar-toggler-icon"> </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarToggle">
                        <ul className="nav navbar-nav navbar-header ml-auto">

                            <li className="nav-item dropdown locale-dropdown">
                                <form>

                                    <div className="nav-link dropdown-toggle c-pointer" id="locale-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={['far', 'globe-americas']} className="fa-fw va-b mr-1" />
                                        {this.props.locale === 'en' &&
                                        <span className="">
                                            <FormattedMessage id="navigation.locale.english" />
                                        </span>
                                        }
                                        {this.props.locale === 'fr' &&
                                        <span className="">
                                            <FormattedMessage id="navigation.locale.french" />
                                        </span>
                                        }
                                    </div>

                                    <div className="dropdown-menu" aria-labelledby="locale-dropdown">
                                        <button onClick={(event) => this.props.handleChangeLocale(event, 'en')} className="dropdown-item c-pointer">
                                            <span className="">
                                                {this.props.locale === 'en' &&
                                                <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw va-b text-primary mr-1" />
                                                }
                                                <FormattedMessage id="navigation.locale.english" />
                                            </span>
                                        </button>
                                        <button onClick={(event) => this.props.handleChangeLocale(event, 'fr')} 
                                            className={`dropdown-item c-pointer ${!this.state.enableFRLocalizationOption && "disabled"}`}>
                                            <span className="">
                                                {this.props.locale === 'fr' &&
                                                <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw va-b text-primary mr-1" />
                                                }
                                                <FormattedMessage id="navigation.locale.french" /> 
                                                { !this.state.enableFRLocalizationOption &&
                                                    <span className="badge badge-secondary text-uppercase small text-muted ml-1">Coming Soon</span>
                                                }
                                            </span>
                                        </button>
                                    </div>

                                </form>
                            </li>

                            {this.validateToken() &&
                            <React.Fragment>

                                <NavItem path="/logout" iconType="far" iconName="sign-out-alt" name={formatMessage({id: "navigation.logout"})} customClasses="d-md-block" upperNav={true} />
                                <NavItem path="https://help.rentmoola.com/hc/en-us" iconType="far" iconName="question-circle" name={formatMessage({id: "navigation.help"})} customClasses="d-md-block" upperNav={true} externalPath={true} />

                                {sessionRole.type === 'TYPE_ADMIN' && this.renderNavItems("TYPE_ADMIN", true)}
                                {sessionRole.type === 'TYPE_MANAGER' && this.renderNavItems("TYPE_MANAGER", true)}
                                {sessionRole.type === 'TYPE_LANDLORD' && this.renderNavItems("TYPE_LANDLORD", true)}
                                {sessionRole.type === 'TYPE_CUSTOMER' && this.renderNavItems("TYPE_CUSTOMER", true)}

                            </React.Fragment>
                            }

                            {!this.validateToken() &&
                            <React.Fragment>

                                {/* <NavItem path="/managers" name={formatMessage({id: "navigation.managers"})} customClasses="d-md-block text-uppercase" upperNav={true} /> */}
                                <NavItem path="/landlords" name="Property Managers" customClasses="d-md-block text-uppercase" upperNav={true} />
                                <NavItem path="/tenants" name={formatMessage({id: "navigation.tenants"})} customClasses="d-md-block text-uppercase" upperNav={true} />
                                <NavItem path="/pricing" name={formatMessage({id: "navigation.pricing"})} customClasses="d-md-block text-uppercase" upperNav={true} />

                            </React.Fragment>
                            }

                        </ul>

                        {!this.validateToken() &&
                        <div>
                            <Link to="/login" className="btn btn-secondary btn-sm text-uppercase my-1 mr-2 ml-3">
                                <FormattedMessage id="navigation.login" />
                            </Link>
                        </div>
                        }

                    </div>

                </nav>

                {this.validateToken() &&
                <nav className="navbar navbar-expand-md navbar-dark navbar-menus bg-dark navbar-toggleable-sm p-0 d-xs-none d-sm-none d-md-none d-lg-block">
                    <div className="navbar-collapse justify-content-sm-center">
                        <ul className="navbar-nav navbar-menus nav-fill text-sm-center">

                            {sessionRole.type === 'TYPE_ADMIN' && this.renderNavItems("TYPE_ADMIN", false)}
                            {sessionRole.type === 'TYPE_MANAGER' && this.renderNavItems("TYPE_MANAGER", false)}
                            {sessionRole.type === 'TYPE_LANDLORD' && this.renderNavItems("TYPE_LANDLORD", false)}
                            {sessionRole.type === 'TYPE_CUSTOMER' && this.renderNavItems("TYPE_CUSTOMER", false)}

                        </ul>
                    </div>
                </nav>
                }

                {(sessionRole != null && sessionRole.type === 'TYPE_LANDLORD' && sessionRole.applicationStatus === 'PAID') &&
                <div className="bg-warning">
                    <div className="container bg-warning">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="mb-0 py-3 small">
                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="va-b" /> Complete your Letus account by selecting which bank account you would like payments to be deposited into.
                                </p>
                            </div>
                            <div className="col-3 text-right">
                                <Link to="/landlord/account/banking" className="btn btn-primary btn-sm my-3">
                                    Choose My Bank Account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </React.Fragment>
        )
    }

    /**
     * Render the navigational items based on the user's type.
     *
     * @param userType - The type of the user logged in.
     * @param displayForMobile - True if the admin navigational items should be rendered for mobile purposes.
     */
    renderNavItems(userType, displayForMobile) {

        let token = this.props.token;
        let sessionRole;
        let showPropertiesForManager = false;

        if(token !== null) {

            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
            sessionRole = JSON.parse(userSession.sessionRole);
            let accessProperties = JSON.parse(userSession.accessProperties);
            showPropertiesForManager = accessProperties ? accessProperties.length > 0 : false;
        }

        if(userType === 'TYPE_ADMIN') {
            return(
                <React.Fragment>

                    <NavItem path="/admin/dashboard" iconType="far" iconName="home" name="Dashboard" upperNav={displayForMobile} />
                    <NavItem path="/admin/companies" iconType="far" iconName="briefcase" name="Companies" upperNav={displayForMobile} />
                    <NavItem path="/admin/properties" iconType="far" iconName="building" name="Properties" upperNav={displayForMobile} />
                    <NavItem path="/admin/managers" iconType="far" iconName="user-tie" name="Managers" upperNav={displayForMobile} />
                    <NavItem path="/admin/landlords" iconType="far" iconName="key" name="Landlords" upperNav={displayForMobile} />
                    <NavItem path="/admin/tenants" iconType="far" iconName="users" name="Tenants" upperNav={displayForMobile} />
                    <NavItem path="/admin/transactions" iconType="far" iconName="credit-card" name="Transactions" upperNav={displayForMobile} />
                    <NavItem path="/admin/services" iconType="far" iconName="plug" name="Services" upperNav={displayForMobile} />
                    <NavItem path="/admin/system" iconType="far" iconName="heartbeat" name="System" upperNav={displayForMobile} />
                    <NavItem path="/admin/account" iconType="far" iconName="cog" name="Account" upperNav={displayForMobile} />

                    <li className={`nav-item nav-link d-block d-md-none border-bottom-0 mt-3 mb-0 px-4`}>

                        <form onSubmit={this.submitSearch}>
                            <FieldText id="searchQuery" required={true} prependIconName="search" prependIconType="fas"
                                       prependTextClass="bg-dark border-secondary border-dark pl-3 pr-0 text-muted"
                                       appendIconName="times" containerClass="mb-0 global-search"
                                       labelClass="d-none mb-0"
                                       fieldClass="form-control-md mb-0 bg-dark border-dark text-white"
                                       fieldColumns="12" labelColums="0" placeholder="Search" parent={this}
                                       value={this.state.searchQuery} maxLength="100"/>
                        </form>

                    </li>

                </React.Fragment>
            );
        }

        if(userType === 'TYPE_MANAGER') {
            return(
                <React.Fragment>

                    <NavItem path="/manager/dashboard" iconType="far" iconName="home-alt" name="Dashboard" upperNav={displayForMobile} />
                    {
                        showPropertiesForManager &&
                        <NavItem path="/manager/properties" iconType="far" iconName="building" name="Properties" upperNav={displayForMobile} />
                    }
                    {sessionRole?.roles?.includes('PRIMARY_MANAGER') && <NavItem path="/manager/managers" iconType="far" iconName="key" name="Managers" upperNav={displayForMobile}/>}
                    <NavItem path="/manager/tenants" iconType="far" iconName="users" name="Tenants" upperNav={displayForMobile} />
                    <NavItem path="/manager/applications" iconType="far" iconName="address-card" name="Applications" upperNav={displayForMobile} />
                    <NavItem path="/manager/transactions" iconType="far" iconName="credit-card" name="Transactions" upperNav={displayForMobile} />
                    <NavItem path="/manager/reports" iconType="far" iconName="chart-pie" name="Reports" upperNav={displayForMobile} />
                    <NavItem path="/manager/services" iconType="far" iconName="plug" name="Services" upperNav={displayForMobile} />
                    <NavItem path="/manager/settings" iconType="far" iconName="cog" name="Settings" upperNav={displayForMobile} />
                    {(sessionRole?.roles?.includes('PRIMARY_MANAGER') || sessionRole?.roles?.includes('FINANCE_MANAGER'))  &&
                        <NavItem path="/manager/bi" iconType="far" iconName="chart-bar" name={<div>Analytics&nbsp;<sup style={{color:'#C92E25'}}>BETA</sup></div>}  upperNav={displayForMobile} />
                    }

                    <li className={`nav-item nav-link d-block d-md-none border-bottom-0 mt-3 mb-0 px-4`}>

                        <form onSubmit={this.submitSearch}>
                            <FieldText id="searchQuery" required={true} prependIconName="search" prependIconType="fas"
                                       prependTextClass="bg-dark border-secondary border-dark pl-3 pr-0 text-muted"
                                       appendIconName="times" containerClass="mb-0 global-search"
                                       labelClass="d-none mb-0"
                                       fieldClass="form-control-md mb-0 bg-dark border-dark text-white"
                                       fieldColumns="12" labelColums="0" placeholder="Search" parent={this}
                                       value={this.state.searchQuery} maxLength="100"/>
                        </form>

                    </li>

                </React.Fragment>
            );
        }

        if(userType === 'TYPE_LANDLORD') {
            if(localStorage.getItem('status') === 'COMPLETE' || localStorage.getItem('status') === 'PAID') {
                return(
                    <React.Fragment>

                        <NavItem path="/landlord/transactions" iconType="far" iconName="credit-card" name="Transactions" upperNav={displayForMobile} />
                        <NavItem path="/landlord/properties" iconType="far" iconName="building" name="Properties" upperNav={displayForMobile} />
                        <NavItem path="/landlord/tenants" iconType="far" iconName="users" name="Tenants" upperNav={displayForMobile} />
                        {/*<NavItem path="/landlord/applications" iconType="far" iconName="address-card" name="Applications" upperNav={displayForMobile} />*/}
                        <NavItem path="/landlord/account" iconType="far" iconName="cog" name="Account" upperNav={displayForMobile} notification={localStorage.getItem('status') === 'PAID'} />

                        <li className={`nav-item nav-link d-block d-md-none border-bottom-0 mt-3 mb-0 px-4`}>

                            <form onSubmit={this.submitSearch}>
                                <FieldText id="searchQuery" required={true} prependIconName="search" prependIconType="fas"
                                           prependTextClass="bg-dark border-secondary border-dark pl-3 pr-0 text-muted"
                                           appendIconName="times" containerClass="mb-0 global-search"
                                           labelClass="d-none mb-0"
                                           fieldClass="form-control-md mb-0 bg-dark border-dark text-white"
                                           fieldColumns="12" labelColums="0" placeholder="Search" parent={this}
                                           value={this.state.searchQuery} maxLength="100"/>
                            </form>

                        </li>

                    </React.Fragment>
                );
            }
        }

        if(userType === 'TYPE_CUSTOMER') {
            return(
                <React.Fragment>
                    <NavItem path="#" key="dashboard" eventKey="dashboard" iconType="far" iconName="user-circle" name="Dashboard" upperNav={displayForMobile} pathToRedirect="/customer/dashboard" />
                </React.Fragment>
            );
        }

    }
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};


export default withRouter(injectIntl(Navigation));
